import React from 'react'
import { StyledDescription } from './TaglineBlock.styles'
import { SvgIcon } from '@web-apps/ui-shared'

type Props = {
  text: string
  icon?: React.FC
  textColor?: string
}

export const TaglineBlock = ({ text, icon, textColor }: Props) => {
  const Icon = icon
  return (
    <StyledDescription $textColor={textColor}>
      {Icon && (
        <SvgIcon width={12} height={12} mr={4}>
          <Icon />
        </SvgIcon>
      )}
      <span>{text}</span>
    </StyledDescription>
  )
}
