import {
  useState,
  useEffect,
  FC,
  PropsWithChildren,
  CSSProperties,
} from 'react'
import {
  StyledContainer,
  AnimationStateEnum,
} from './AccordionAnimation.styles'

export const AccordionAnimation: FC<
  PropsWithChildren<{
    state: AnimationStateEnum
    isButton?: boolean
    overflow?: CSSProperties['overflow']
  }>
> = ({ state, children, isButton, overflow = 'initial' }) => {
  const [shouldRender, setRender] = useState(false)

  useEffect(() => {
    if (state !== AnimationStateEnum.HIDDEN) setRender(true)
  }, [state])

  const onAnimationEnd = () => {
    if (state === AnimationStateEnum.HIDDEN) setRender(false)
  }

  return shouldRender ? (
    <StyledContainer
      $isButton={isButton}
      $state={state}
      style={{ overflow }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </StyledContainer>
  ) : null
}
