import { api, callApiEndpoint } from '@web-apps/utils-shared'
import {
  ContactListFormType,
  ContactListSectionType,
  ContactListType,
} from '@web-apps/utils-types'

const contactListApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContactList: builder.query<ContactListType, string>({
      query: (path) => ({
        path,
        method: 'get',
      }),
      providesTags: ['ContactListDetails'],
    }),
    createContactListSection: builder.mutation<
      Record<string, never>,
      { pageId: string; data: ContactListFormType }
    >({
      async queryFn({ data, pageId }, _queryApi, _extraOptions, baseQuery) {
        const { data: contactListData, error: contactListError } =
          await baseQuery({
            path: `/contact-lists?page_id=${pageId}`,
            method: 'post',
            data: {
              collect_email: data.collect_email,
              collect_phone: data.collect_phone,
              submit_label: data.submit_label,
            },
          })
        if (contactListError) return { error: contactListError }

        const { error: sectionError } = await callApiEndpoint(
          'addCreatorPageSectionAtomic',
          _queryApi,
          {
            pageId,
            imageData: data.imageData,
            sectionData: {
              label: data.label,
              tagline: data.tagline,
              type: data.type,
              styles: data.styles,
              contact_list_id: contactListData.id,
              published: data.published,
            },
            stopInvalidatesTags: true,
          }
        )

        if (sectionError) return { error: sectionError }

        return { data: {} }
      },
      invalidatesTags: (result) =>
        result ? ['CreatorPage', 'CreatorDetails'] : [],
    }),
    editContactListSection: builder.mutation<
      Record<string, never>,
      {
        section: ContactListSectionType
        contactList: ContactListType
        data: ContactListFormType
      }
    >({
      async queryFn(
        { data, contactList, section },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const { error: contactListError } = await baseQuery({
          path: contactList._links.self.href,
          method: 'put',
          data: {
            collect_email: data.collect_email,
            collect_phone: data.collect_phone,
            submit_label: data.submit_label,
            published: data.published,
          },
        })
        if (contactListError) return { error: contactListError }

        const { error: sectionError } = await callApiEndpoint(
          'editCreatorPageSectionAtomic',
          _queryApi,
          {
            _links: section._links,
            imageData: data.imageData,
            sectionData: {
              label: data.label,
              tagline: data.tagline,
              published: data.published,
              type: data.type,
              styles: data.styles,
              contact_list_id: contactList._links.self.id,
            },
            stopInvalidatesTags: true,
          }
        )

        if (sectionError) return { error: sectionError }

        return { data: {} }
      },
      invalidatesTags: (result) =>
        result ? ['CreatorPage', 'CreatorDetails', 'ContactListDetails'] : [],
    }),
    createSignup: builder.mutation<
      void,
      { contactListId: string; email: string }
    >({
      query: ({ contactListId, email }) => ({
        path: `/contact-lists/${contactListId}/signups`,
        method: 'post',
        data: { email },
      }),
      invalidatesTags: ['ContactListDetails'],
    }),
  }),
})

export const {
  useGetContactListQuery,
  useCreateContactListSectionMutation,
  useEditContactListSectionMutation,
  useCreateSignupMutation,
} = contactListApi
