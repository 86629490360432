import { useState, useEffect } from 'react'

import { Icon, IconButton, Theme } from '@web-apps/ui-shared'
import { isInInstagramIosBrowser } from '@web-apps/utils-shared'

import {
  StyledFooterBanner,
  StyledFooterBannerWrapper,
  StyledFooterContainer,
} from './FooterBanner.component.styles'

type Props = {
  title: string
  url: string
  onClick?: () => void
  onClose: () => void
}

export const FooterBanner = ({ title, url, onClick, onClose }: Props) => {
  const [isInstagram, setIsInstagram] = useState(false)

  useEffect(() => {
    setIsInstagram(isInInstagramIosBrowser())
  }, [])

  return (
    <StyledFooterBanner $isInstagram={isInstagram}>
      <StyledFooterBannerWrapper href={url} onClick={onClick}>
        <StyledFooterContainer>
          <span>{title}</span>
          <Icon.ZezamLogoOld
            width={67}
            height={12}
            fillColor={Theme.Colors.typography.inverse}
          />
        </StyledFooterContainer>

        <IconButton
          icon="Close"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            onClose()
          }}
          iconProps={{
            width: '12px',
            height: '12px',
          }}
        />
      </StyledFooterBannerWrapper>
    </StyledFooterBanner>
  )
}
