import styled, { css } from 'styled-components'

import {
  Avatar,
  Icon,
  TextEllipsis,
  Theme,
  Typography,
  media,
  BREAKPOINTS,
} from '@web-apps/ui-shared'

import { CreatorPageType } from '@web-apps/utils-types'

import { computeAvatarUrl } from './Header.utils'

type StickyHeaderProps = {
  creatorPage: CreatorPageType
  onMoreOptionsClick?: () => void
}

const INLINE_POSITION_IN_PIXELS = 16

const StyledFixedWrapper = styled.div`
  position: fixed;
  top: 8px;
  width: 100%;

  z-index: ${Theme.CreatorPage.footer.zIndex};

  display: flex;
  justify-content: center;

  ${media.to(
    BREAKPOINTS.sm,
    css`
      left: 0;
      right: 0;
      width: auto;
      margin-inline: ${INLINE_POSITION_IN_PIXELS}px;
    `
  )}
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: rgba(255, 255, 255, 0.48);
  backdrop-filter: blur(8px);
  border-radius: 64px;

  padding: 8px;

  width: 100%;
  ${media.from(
    BREAKPOINTS.sm,
    css`
      width: ${Theme.CreatorPage.wrapper.maxWidth + 10}px;
    `
  )}

  max-width: ${Theme.CreatorPage.wrapper.maxWidth + 10}px;

  /* to make ellipsis work for long texts */
  h4 {
    max-width: 60%;
  }
`

const StyledMoreButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 40px;
  height: 40px;

  background: #111111;
  border-radius: 160px;

  border: none;
`

export const StickyHeader = ({
  creatorPage,
  onMoreOptionsClick,
}: StickyHeaderProps) => {
  return (
    <StyledFixedWrapper>
      <StyledContainer>
        <Avatar
          url={computeAvatarUrl({ creatorPage })}
          name={creatorPage.name}
          size={40}
        />
        <Typography variant="h4">
          <TextEllipsis>{creatorPage.name}</TextEllipsis>
        </Typography>
        <StyledMoreButtonContainer onClick={onMoreOptionsClick}>
          <Icon.More fillColor={Theme.Colors.typography.inverse} />
        </StyledMoreButtonContainer>
      </StyledContainer>
    </StyledFixedWrapper>
  )
}
