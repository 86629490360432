import { SectionLinkAdapterInterface } from '.'
import { CustomLinkProps } from '../components'

import { baseCustomLinkPropsFromSection } from './sectionLinks.adapter'

import { SectionTypeEnum } from '@web-apps/utils-types'

export const affiliateLinkPropsFromSection: SectionLinkAdapterInterface = (
  section,
  configs
) => {
  if (section.type !== SectionTypeEnum.AFFILIATE_LINK)
    throw new Error('Invalid section type')

  const baseInfo = baseCustomLinkPropsFromSection(section, configs)

  const affiliateLinkProps: CustomLinkProps = {
    ...baseInfo,
    href: section._links.alternate?.href,
    isExternalHref: true,
    rel: 'noreferrer',
    sectionHref: section._links.self.href,
  }

  return affiliateLinkProps
}
