import { CustomizationFormProperties } from '@web-apps/utils-types'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;

  & > div {
    grid-area: 1 / 1;
  }
`

export const StyledRemoveButton = styled.div`
  margin-top: 8px;
  padding: 8px;
  cursor: pointer;

  border-radius: 72px;
  background-color: ${({ theme }) =>
    theme.customization[CustomizationFormProperties.BUTTONS_BACKGROUND_COLOR]};
`
