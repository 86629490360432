import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import Skeleton from 'react-loading-skeleton'

import {
  Box,
  Flex,
  Icon,
  ShareLinkContext,
  ShareLinkType,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import {
  BackgroundType,
  CreatorPageMessagesType,
  CreatorPageType,
  CustomizationFormProperties,
  ExternalLinkMode,
  isLeafSection,
  LeafsSectionType,
  LinkableSectionType,
  ProductRecommendationsSectionType,
  SectionType,
  SectionTypeEnum,
  SocialLinkType,
  SocialsPlacement,
} from '@web-apps/utils-types'

import {
  CustomLink,
  CustomLinkProps,
  ExternalEmbedLink,
  Header,
  StickyHeader,
} from '../components'

import {
  StyledBackground,
  StyledContainer,
  StyledFullWrapper,
  StyledHeaderImageContainer,
  StyledHeaderOptionsListItem,
  StyledHeaderOptionsPopover,
  StyledSectionsContainer,
  StyledShareLinkPopup,
  StyledSkeletonSectionContainer,
} from './CreatorPageContent.styles'

import { sectionLinkAdapterFacade } from '../adapters'

import { computeBackgroundInfoForStyles } from '../utils'
import { ContentSection } from '../components/ContentSection'
import { BackgroundImage } from '../components/BackgroundImage'
import { SocialLinks } from '../components/SocialLinks'
import { useTranslation } from 'react-i18next'

import {
  creatorUrlBuilders,
  getEmbedCategoryTitle,
  nativeShareWrapper,
} from '@web-apps/utils-shared'
import { ContainerSection } from '../components/ContainerSection/ContainerSection.component'
import { AccordionWrapper } from '../components/AccordionWrapper'

type ContentWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  creatorPage: CreatorPageType
  socialLinkTracker?: (socialLink: SocialLinkType) => void
  sectionLinkTracker?: (section: SectionType) => void
  messages: CreatorPageMessagesType
  useRelativePaths?: boolean
  CustomLinkWrapper?: React.FC<CustomLinkProps>
  hideRestrictPopup?: boolean
  skipImageErrorHandler?: boolean
  skipIntersectionObserver?: boolean
  enableShareFeature?: boolean
  onShareClick?: () => void
  translations?: {
    restrictPopup: {
      title: string
      description: string
      proceed: string
      cancel: string
    }
  }
  renderContentWrapper?: (children: React.ReactNode) => React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useCreateSignupMutation: UseMutation<any>
  hideTikTokSections?: boolean
  preventContactListSubmission?: boolean
  isPreview?: boolean
}

type RenderSectionOptionsType = {
  isNestedSection: boolean
  containerSection?: ProductRecommendationsSectionType
  isLoading?: boolean
  hideTikTokSections?: boolean
}

const CustomLinkFragment: React.FC<CustomLinkProps> = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
)

const useOnScreen = (
  ref: React.RefObject<HTMLElement> | null,
  options?: { skip: boolean }
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const skip = options?.skip ?? false

  const observer = useMemo(() => {
    const isIntersectionObserverSupported =
      typeof window !== 'undefined' && 'IntersectionObserver' in window
    if (!isIntersectionObserverSupported || skip) return undefined
    return new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio > 0) setIntersecting(true)
      },
      {
        root: null,
        rootMargin: '0px',
      }
    )
  }, [skip])

  useEffect(() => {
    if (!ref?.current || !observer || skip) return

    // needed because When the IntersectionObserver is instantiated the callback is run once as detection of whether the element is in view or not (and correctly reports the intersecting attribute as false if it's not in view).
    // source: https://stackoverflow.com/a/47855484/1085387
    setTimeout(() => {
      if (!ref.current) return
      observer.observe(ref.current)
    }, 500)

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [ref, observer, skip])

  return isIntersecting
}

const SectionVisibilityDetector = ({
  renderSection,
  skip,
}: {
  renderSection: (isVisible: boolean) => JSX.Element | null | undefined
  skip: boolean
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(containerRef, {
    skip,
  })

  return <div ref={containerRef}>{renderSection(isVisible)}</div>
}

export const CreatorPageContent = ({
  creatorPage,
  socialLinkTracker,
  useRelativePaths = false,
  messages,
  sectionLinkTracker,
  CustomLinkWrapper = CustomLinkFragment,
  translations,
  hideRestrictPopup = false,
  skipImageErrorHandler,
  useCreateSignupMutation,
  renderContentWrapper = (children) => children,
  skipIntersectionObserver = false,
  enableShareFeature = false,
  hideTikTokSections,
  onShareClick,
  preventContactListSubmission = false,
  isPreview = false,
  ...divProps
}: ContentWrapperProps) => {
  const { t } = useTranslation(['app', 'creator'])

  const backgroundInfo = computeBackgroundInfoForStyles(creatorPage)
  const showSocialsOnBottom =
    creatorPage.styles[CustomizationFormProperties.SOCIALS_PLACEMENT] ===
    SocialsPlacement.BOTTOM

  const containerRef = useRef<HTMLDivElement>(null)
  const [shareLinkContextData, setShareLink] = useState<ShareLinkType>({
    url: '',
  })

  const renderCustomLinksForSection = (
    section: LinkableSectionType,
    options?: RenderSectionOptionsType
  ) => {
    const customLinkProps = sectionLinkAdapterFacade(section, {
      messages,
      useRelativePaths: useRelativePaths,
      context: options?.containerSection
        ? { containerSection: options.containerSection }
        : undefined,
    })

    return customLinkProps ? (
      <CustomLinkWrapper {...customLinkProps} key={customLinkProps.id}>
        <CustomLink
          isNested={options?.isNestedSection || false}
          key={customLinkProps.id}
          isExternalHref={!useRelativePaths}
          onMouseDown={
            sectionLinkTracker
              ? (e) => {
                  //  middle mouse click
                  if (e.button === 1) {
                    sectionLinkTracker(section)
                  }
                }
              : undefined
          }
          onClick={
            sectionLinkTracker ? () => sectionLinkTracker(section) : undefined
          }
          hideRestrictPopup={hideRestrictPopup}
          skipImageErrorHandler={skipImageErrorHandler}
          translations={translations?.restrictPopup}
          {...customLinkProps}
        />
      </CustomLinkWrapper>
    ) : null
  }

  const renderLeafSection = (
    section: LeafsSectionType,
    options?: RenderSectionOptionsType
  ) => {
    if (
      section.type === SectionTypeEnum.EXTERNAL_LINK &&
      section.mode === ExternalLinkMode.EMBED
    ) {
      if (!section._links.about?.href) return null

      const { name, type } = getEmbedCategoryTitle(section._links.about.href)

      return (
        <AccordionWrapper
          section={section}
          defaultLabel={t(`creator:sections.${type}`, { name })}
        >
          {options?.isLoading ? (
            <Skeleton
              wrapper={StyledSkeletonSectionContainer}
              height="100%"
              baseColor="#FAFAFA"
              highlightColor="#bbb"
              style={{
                opacity: 0.2,
              }}
            />
          ) : (
            <ExternalEmbedLink
              hideTikTokSections={hideTikTokSections}
              key={section._links.self.id}
              url={section._links.about?.href}
              openLinkOnClick={section.styles?.['is_video_link']}
            />
          )}
        </AccordionWrapper>
      )
    }

    if (section.type === SectionTypeEnum.CONTENT) {
      return (
        <AccordionWrapper
          section={section}
          defaultLabel={t(`creator:sections.content`)}
        >
          <ContentSection key={section._links.self.id} data={section} />
        </AccordionWrapper>
      )
    }

    if (section.type === SectionTypeEnum.CONTACT_LIST) {
      return null
    }

    return renderCustomLinksForSection(section, options)
  }

  const [showStickyHeader, setShowStickyHeader] = useState(false)
  const [showHeaderOptions, setShowHeaderOptions] = useState(false)
  useEffect(() => {
    if (!enableShareFeature) return

    // This is a hack for nextJs, where scroll event does not fire -- https://github.com/vercel/next.js/discussions/18299
    // I have tried to use window and document.body to addEventListener without success
    const scrollableWrapper = document.getElementById('scrollable-wrapper')
    const onScroll = () => {
      setShowStickyHeader(
        (scrollableWrapper ? scrollableWrapper.scrollTop : window.screenY) > 120
      )
    }

    ;(scrollableWrapper || window).addEventListener('scroll', onScroll)

    return () =>
      (scrollableWrapper || window).removeEventListener('scroll', onScroll)
  }, [enableShareFeature])

  const onShare = () => {
    setShowHeaderOptions(false)

    if (onShareClick) onShareClick()
    const url = creatorUrlBuilders.creatorUrlBuilder({
      creatorSlug: creatorPage._links.self.name,
    })

    nativeShareWrapper(url, () =>
      setShareLink({ url, container: containerRef.current })
    )
  }

  return (
    <ShareLinkContext.Provider
      value={{ ...shareLinkContextData, setShareLink }}
    >
      <StyledBackground $backgroundInfo={backgroundInfo}>
        {backgroundInfo.backgroundType === BackgroundType.Image && (
          <BackgroundImage
            isPreview={isPreview}
            image={backgroundInfo.image}
            backgroundInfo={backgroundInfo}
          />
        )}
      </StyledBackground>

      {shareLinkContextData?.url && (
        <StyledShareLinkPopup
          title={t('app:share_popup.share_link')}
          messages={{
            t,
            namespace: 'app',
            copyButton: {
              buttonText: t('app:button_copy'),
              copiedTooltipText: t('app:button_tooltip_copied'),
            },
          }}
          backdropMobileOnly={false}
          enableCloseButtonOnDesktop
          backdropStyles={{
            zIndex: Theme.CreatorPage.footer.zIndex + 1,
          }}
        />
      )}

      {renderContentWrapper(
        <StyledFullWrapper
          translate="no"
          $backgroundInfo={backgroundInfo}
          $withAdditionalPadding={isPreview}
          ref={containerRef}
          {...divProps}
        >
          <StyledContainer>
            {backgroundInfo.backgroundType === BackgroundType.Header && (
              <StyledHeaderImageContainer $backgroundInfo={backgroundInfo}>
                <BackgroundImage
                  image={backgroundInfo.image}
                  backgroundInfo={backgroundInfo}
                />
              </StyledHeaderImageContainer>
            )}

            {showStickyHeader && (
              <StickyHeader
                creatorPage={creatorPage}
                onMoreOptionsClick={() => {
                  setShowHeaderOptions(true)
                }}
              />
            )}

            {showHeaderOptions &&
              containerRef.current &&
              createPortal(
                <StyledHeaderOptionsPopover
                  title={creatorPage.name}
                  onClose={() => setShowHeaderOptions(false)}
                  hideHeaderOnDesktop={false}
                  backdropMobileOnly={false}
                  enableCloseButtonOnDesktop
                  backdropStyles={{
                    zIndex: Theme.CreatorPage.footer.zIndex + 1,
                  }}
                >
                  <StyledHeaderOptionsListItem as="button" onClick={onShare}>
                    <Flex justify="space-between">
                      <Flex gap={20}>
                        <Icon.Share width={24} height={24} />
                        <Typography variant="h4">
                          {t('app:header_options_popup.share_page')}
                        </Typography>
                      </Flex>
                      <Icon.ArrowRight size={16} />
                    </Flex>
                  </StyledHeaderOptionsListItem>
                  <StyledHeaderOptionsListItem href="https://zezam.io">
                    <Flex justify="space-between">
                      <Flex gap={20}>
                        <Icon.ZezamLogo width={24} height={24} />
                        <Typography variant="h4">
                          {t('app:header_options_popup.create_page')}
                        </Typography>
                      </Flex>
                      <Icon.LinkExternal width={24} height={24} />
                    </Flex>
                  </StyledHeaderOptionsListItem>
                </StyledHeaderOptionsPopover>,
                containerRef.current
              )}

            <Header
              creatorPage={creatorPage}
              socialLinkTracker={socialLinkTracker}
              enableShareFeature={enableShareFeature}
              onShareClick={onShare}
            />

            <StyledSectionsContainer>
              {creatorPage.sections &&
                creatorPage.sections
                  .filter(({ published }) => published)
                  .map((s) => {
                    const isEmbed = Boolean(
                      s.type === SectionTypeEnum.EXTERNAL_LINK &&
                        s.mode === ExternalLinkMode.EMBED
                    )

                    return (
                      <SectionVisibilityDetector
                        key={s._links.self.id}
                        skip={!isEmbed || skipIntersectionObserver}
                        renderSection={(isVisible) =>
                          isLeafSection(s) ? (
                            renderLeafSection(s as LeafsSectionType, {
                              isLoading: skipIntersectionObserver
                                ? false
                                : !isVisible && isEmbed,
                              isNestedSection: false,
                              hideTikTokSections,
                            })
                          ) : s.type === SectionTypeEnum.CONTAINER ? (
                            <AccordionWrapper
                              section={s}
                              defaultLabel={t(
                                'creator:product_recommendations.default_grid_title'
                              )}
                            >
                              <ContainerSection
                                section={s}
                                renderLeafSection={renderLeafSection}
                              />
                            </AccordionWrapper>
                          ) : null
                        }
                      />
                    )
                  })}
            </StyledSectionsContainer>

            {showSocialsOnBottom && (
              <Box py={32}>
                <SocialLinks
                  links={creatorPage.social_links}
                  socialLinkTracker={socialLinkTracker}
                />
              </Box>
            )}
          </StyledContainer>
        </StyledFullWrapper>
      )}
    </ShareLinkContext.Provider>
  )
}
