import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import cloneDeep from 'lodash.clonedeep'

import {
  api,
  callApiEndpoint,
  encodeUriWhenNotEncoded,
  fromAffiliateLinkAPI,
  truncateText,
  fromPublicAffiliateLinkAPI,
} from '@web-apps/utils-shared'
import { fromCreatorPageAffiliateLinksAnalyticsAPI } from '../adapters/affiliateAnalytics.adapter'
import {
  CurrencyIdentifierEnum,
  ImageFormType,
  ImageNamesEnum,
  BackgroundType,
  CreatorPageAffiliateAnalyticsAPIPayload,
  CreatorPageAffiliateLinksAnalytics,
  CreatorPageSimpleAnalyticsAPIPayload,
  CreatorPageCommerceSettings,
  CreatorPageType,
  CustomizationFormProperties,
  CustomizationPropsType,
  MyPageAppearanceApiPayload,
  SectionType,
  SectionTypeEnum,
  SectionVariantEnum,
  SectionContainerItemFormType,
  SectionDataType,
  ImportProfileResponseType,
  ImageResponseType,
  AffiliateLinkType,
  PublicAffiliateLinkType,
} from '@web-apps/utils-types'
import { MAX_TITLE_LENGTH } from '@web-apps/ui-shared'

const encodeSectionLink = (section?: SectionType) => {
  if (!section) return

  if (section._links?.about?.href) {
    section._links.about.href = Buffer.from(section._links.about.href).toString(
      'base64'
    )
  }

  if (section._links?.alternate?.href) {
    section._links.alternate.href = Buffer.from(
      section._links.alternate.href
    ).toString('base64')
  }

  if (section._links?.affiliate?.href) {
    section._links.affiliate.href = Buffer.from(
      section._links.affiliate.href
    ).toString('base64')
  }
}

export const decodeLink = (url: string) =>
  Buffer.from(url, 'base64').toString('binary')
export const decodeSectionLinks = (section?: SectionType) => {
  if (!section) return

  if (section._links?.about?.href)
    section._links.about.href = decodeLink(section._links.about.href)

  if (section._links?.alternate?.href)
    section._links.alternate.href = decodeLink(section._links.alternate.href)

  if (section._links?.affiliate?.href)
    section._links.affiliate.href = decodeLink(section._links.affiliate.href)
}

export const creatorAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getCreator: builder.query<
      CreatorPageType,
      { slug: string; encodeSectionLinksNeeded?: boolean }
    >({
      query: ({ slug }) => ({
        path: `users/${slug}/creator-page`,
        method: 'get',
      }),
      transformResponse: (
        creatorPage: CreatorPageType,
        _,
        { encodeSectionLinksNeeded = false }
      ) => {
        if (!encodeSectionLinksNeeded) return creatorPage

        // this is needed to hide NSFW link for public-app
        const creatorPageTransformed = cloneDeep(creatorPage)
        const sections = creatorPageTransformed.sections || []
        sections.forEach((section) => {
          encodeSectionLink(section)

          if (section.type === SectionTypeEnum.CONTAINER) {
            const items = section.items || []
            items.forEach((s) => encodeSectionLink(s as SectionType))
          }
        })

        encodeSectionLink(creatorPageTransformed.redirect?.section)

        return creatorPageTransformed
      },
      providesTags: ['CreatorPage'],
    }),
    getCreatorPage: builder.query<CreatorPageType, { pageId: string }>({
      query: ({ pageId }) => ({
        path: `/creator-pages/${pageId}/preview`,
        method: 'get',
      }),
      providesTags: ['CreatorDetails'],
    }),
    getCreatorPageSimpleAnalyticsAggregation: builder.query<
      Omit<CreatorPageSimpleAnalyticsAPIPayload, 'timeseries'>,
      { pageId: string; period: string }
    >({
      query: ({ pageId, period }) => ({
        path: `/creator-pages/${pageId}/analytics/aggregate?period=${period}`,
        method: 'get',
      }),
    }),
    getCreatorPageSimpleAnalytics: builder.query<
      CreatorPageSimpleAnalyticsAPIPayload,
      { pageId: string; period: string; advanced?: boolean }
    >({
      query: ({ pageId, period, advanced = false }) => ({
        path: `/creator-pages/${pageId}/analytics?period=${period}&advanced=${advanced}`,
        method: 'get',
      }),
    }),
    getCreatorPageAffiliateLinksAnalytics: builder.query<
      CreatorPageAffiliateLinksAnalytics,
      { path: string }
    >({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
      transformResponse: fromCreatorPageAffiliateLinksAnalyticsAPI,
    }),
    getCreatorPageAffiliateAnalytics: builder.query<
      CreatorPageAffiliateAnalyticsAPIPayload,
      { path: string }
    >({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
    }),
    getAffiliateLinkAnalytics: builder.query<
      CreatorPageAffiliateAnalyticsAPIPayload,
      { linkId?: string; period: string; pageId: string }
    >({
      query: ({ linkId, pageId, period }) => {
        const path = linkId
          ? `/creator-pages/${pageId}/affiliates/analytics?period=${period}&link-id=${linkId}`
          : `/creator-pages/${pageId}/affiliates/analytics?orphan&period=${period}`

        return {
          path,
          method: 'get',
        }
      },
    }),
    // TODO: Affiliate-Links: I keep it for now for grid sections only,
    //  we will replace it with the correct method for grids in future MRs
    addCreatorPageSectionAtomic: builder.mutation<
      SectionType,
      {
        sectionData: SectionDataType
        imageData?: ImageFormType
        redirect?: boolean
        pageId: string
        containerId?: string
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { pageId, containerId, redirect, sectionData, imageData },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        // 1. Add section
        const createSectionPayload = cloneDeep(sectionData)

        // removing uploaded images inside items because they will be uploaded independently below
        const items = createSectionPayload.items || []
        items.forEach((i) => delete i.imageData)
        let section
        try {
          section = await callApiEndpoint('addCreatorPageSection', _queryApi, {
            pageId,
            containerId,
            data: createSectionPayload,
            stopInvalidatesTags: true,
          })
        } catch (error) {
          return { error }
        }

        const sectionId = section._links.self.id

        // TODO: to review and improve with backend - UPLOADING IMAGES FOR ITEMS
        // TODO: use callApiEndpoint instead for get section info?? (we do not have this endpoint yet)

        // When it has items and need to upload images - one by one
        const itemHasImageToUpload = (item: SectionContainerItemFormType) =>
          item.imageData && !item.imageData.hasImageSource
        const hasImagesToUploadForNestItems =
          sectionData.items && sectionData.items.some(itemHasImageToUpload)
        if (hasImagesToUploadForNestItems) {
          const { error: getSectionError, data: containerSection } =
            await fetchWithBQ({
              path: `/creator-pages/${pageId}/sections/${sectionId}`,
              method: 'get',
            })

          if (getSectionError) return { error: getSectionError }

          // Uploading images
          const uploadImagePromises: Promise<QueryReturnValue>[] = []
          if (
            (containerSection.items || []).length ===
            (sectionData.items || []).length
          ) {
            ;(sectionData.items || []).forEach((item, i) => {
              const storedSectionItem = (containerSection.items || [])[i]
              const sectionId = storedSectionItem?._links?.self?.id
              if (sectionId && item.imageData && itemHasImageToUpload(item)) {
                uploadImagePromises.push(
                  callApiEndpoint('setCreatorPageImage', _queryApi, {
                    path: `/creator-pages/${pageId}/sections/${sectionId}/images`,
                    name: ImageNamesEnum.DEFAULT,
                    file: item.imageData.imageFile,
                    stopInvalidatesTags: true,
                  })
                )
              }
            })

            try {
              await Promise.all(uploadImagePromises)
            } catch (error) {
              return { error }
            }
          }

          return { data: section }
        }

        // TODO: END UPLOADING IMAGES FOR ITEMS

        const promises: Promise<QueryReturnValue>[] = []

        // 2. update redirect if needed
        if (redirect) {
          promises.push(
            callApiEndpoint('setPageRedirect', _queryApi, {
              pageId,
              sectionId,
            })
          )
        }

        // 3. upload image if needed
        if (
          imageData?.imageFile &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          promises.push(
            callApiEndpoint('setCreatorPageImage', _queryApi, {
              path: `/creator-pages/${pageId}/sections/${sectionId}/images`,
              name: ImageNamesEnum.DEFAULT,
              file: imageData.imageFile,
              stopInvalidatesTags: true,
            })
          )
        }

        try {
          await Promise.all(promises)
        } catch (error) {
          return { error }
        }

        // it was well succeeded
        return { data: section }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),

    // TODO: Affiliate-Links: replace it to the createAffiliateSectionAtomic method
    addCreatorPageSectionFromTrendingProductAtomic: builder.mutation<
      SectionType,
      {
        sectionData: SectionDataType
        pageId: string
        containerId?: string
        createAffiliateLinkEndpoint?: string
      }
    >({
      async queryFn(
        { sectionData, pageId, containerId, createAffiliateLinkEndpoint },
        _queryApi
      ) {
        // 1. Create affiliate link (either it is a grid or a single section)
        const linkData = sectionData.items?.[0] || sectionData

        const pageTitle = linkData?.label
          ? truncateText(linkData.label, {
              maxLength: MAX_TITLE_LENGTH,
              useEllipsis: true,
            })
          : undefined

        const creationResponse = await callApiEndpoint(
          'createAffiliateLinkAtomic',
          _queryApi,
          {
            uri: linkData?.href,
            pageId,
            createAffiliateLinkEndpoint: createAffiliateLinkEndpoint,
            pageTitle: pageTitle,
            imageUrl: linkData?.image_source
              ? linkData?.image_source
              : undefined,
          }
        )

        // 2. Add section, as we only add one section atm, we take it and populate with the affiliate link ID
        const section = await callApiEndpoint(
          'addCreatorPageSection',
          _queryApi,
          {
            pageId,
            containerId,
            data: {
              ...sectionData,
              ...(!sectionData.items && {
                affiliate_link_id: creationResponse.id,
              }),
              ...(sectionData.items && {
                items: [
                  {
                    ...sectionData.items?.[0],
                    ...{
                      affiliate_link_id: creationResponse.id,
                    },
                  },
                ],
              }),
            },
            stopInvalidatesTags: true,
          }
        )

        const promises: Promise<QueryReturnValue>[] = []

        try {
          await Promise.all(promises)
        } catch (error) {
          return { error }
        }

        // it was well succeeded
        return { data: section }
      },
      invalidatesTags: (result) =>
        result ? ['CreatorDetails', 'CreatorPage'] : [],
    }),
    // TODO: Affiliate-Links: I keep it for now for grid sections only,
    //  we will replace it with the correct method for grids in future MRs
    editCreatorPageSectionAtomic: builder.mutation<
      Record<string, never>,
      {
        _links: SectionType['_links']
        redirect?: 'delete' | 'update'
        sectionData: SectionDataType
        imageData?: ImageFormType
        items?: {
          orderById: string[]
        }
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { _links, redirect, sectionData, imageData, items },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        // 1. Edit section
        await callApiEndpoint('editCreatorPageSection', _queryApi, {
          path: _links.self.href,
          data: sectionData,
          stopInvalidatesTags: true,
        })

        // update items order if needed
        if (items?.orderById) {
          const { error: updateItemsOrderError } = await fetchWithBQ({
            path: `/creator-pages/${_links.parent.id}/sections/${_links.self.id}/sections/order`,
            method: 'put',
            data: {
              items: items?.orderById,
            },
          })

          if (updateItemsOrderError) return { error: updateItemsOrderError }
        }

        // 2. update redirect if needed
        if (redirect === 'update') {
          await callApiEndpoint('setPageRedirect', _queryApi, {
            pageId: _links.parent.id,
            sectionId: _links.self.id,
          })
        }

        // 2. delete redirect if needed
        if (redirect === 'delete') {
          await callApiEndpoint('deletePageRedirect', _queryApi, {
            pageId: _links.parent.id,
          })
        }

        // 3. Upload image from a device if needed
        if (
          imageData?.imageFile &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          try {
            await callApiEndpoint('setCreatorPageImage', _queryApi, {
              path: _links['self/edit-image']?.href,
              name: ImageNamesEnum.DEFAULT,
              file: imageData.imageFile,
              stopInvalidatesTags: true,
            })
          } catch (error) {
            return { error }
          }
        }

        // 4. upload image from external source if needed
        if (
          imageData?.hasImageSource &&
          imageData?.href &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          try {
            await callApiEndpoint('setCreatorPageImageFromSource', _queryApi, {
              path: _links['self/edit-image']?.href,
              name: ImageNamesEnum.DEFAULT,
              source: encodeUriWhenNotEncoded(imageData.href),
              stopInvalidatesTags: true,
            })
          } catch (error) {
            return { error }
          }
        }

        // 5. delete image if needed
        if (sectionData.styles.variant === SectionVariantEnum.PLAIN) {
          const deleteImage = _links['self/delete-image']?.find(
            (item: ImageResponseType) =>
              item.name === ImageNamesEnum.DEFAULT ||
              item.name === ImageNamesEnum.SECTION
          )

          if (deleteImage) {
            await callApiEndpoint('deleteCreatorPageImage', _queryApi, {
              path: deleteImage.href,
              stopInvalidatesTags: true,
            })
          }
        }

        // it was well succeeded
        return { data: {} }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
    updateCreatorPageSocialLinks: builder.mutation<
      Record<string, never>,
      {
        userNanoId: string
        items: CreatorPageType['social_links']
        styles: CreatorPageType['styles']
        stylesPath: string
      }
    >({
      async queryFn(
        { userNanoId, items, styles, stylesPath },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const [{ error: socialsError }, { error: stylesError }] =
          await Promise.all([
            baseQuery({
              path: `/users/${userNanoId}/social-links?v2`,
              method: 'put',
              data: { items },
            }),
            baseQuery({
              path: stylesPath,
              method: 'put',
              data: styles,
            }),
          ])

        const error = socialsError || stylesError
        if (error) return { error }

        return { data: {} }
      },
      invalidatesTags: (result) =>
        result ? ['CreatorDetails', 'CreatorPage'] : [],
    }),
    getCreatorPageCommerceSettings: builder.query<
      CreatorPageCommerceSettings,
      { pageId?: string; path?: string }
    >({
      query: ({ pageId, path }) => ({
        path: path || `/creator-pages/${pageId}/settings/ecommerce`,
        method: 'get',
      }),
      transformResponse: (responseData: {
        default_currency: CurrencyIdentifierEnum
        convert_affiliate_links: boolean
      }) => ({
        defaultCurrency: responseData.default_currency,
        convertAffiliateLinks: responseData.convert_affiliate_links,
      }),
      providesTags: ['CreatorPageCommerceSettings'],
    }),
    updateCreatorPageCommerceSettings: builder.mutation<
      void,
      {
        pageId?: string
        path?: string
        data: CreatorPageCommerceSettings
      }
    >({
      query: ({ pageId, path, data: { defaultCurrency } }) => ({
        path: path || `/creator-pages/${pageId}/settings/ecommerce`,
        method: 'put',
        data: {
          default_currency: defaultCurrency,
          /* always true as request per https://gitlab.com/zezam/frontend/web-apps/-/issues/979 */
          convert_affiliate_links: true,
        },
      }),
      invalidatesTags: [
        'CreatorPageCommerceSettings',
        'UserAffiliateBalance',
        'DashboardStarterChallenge',
      ],
    }),
    updateCreatorPageAppearance: builder.mutation<
      Record<string, never>,
      MyPageAppearanceApiPayload & { path: string } & {
        isStyleFormDirty?: boolean
      }
    >({
      async queryFn(
        {
          _links,
          path,
          backgroundImage,
          gradientStartColor,
          gradientEndColor,
          name,
          about,
          profileImage,
          profileImageAction,
          hideFooterBanner,
          isStyleFormDirty = false,
          ...data
        },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const stylesFormData: CustomizationPropsType = {
          ...(data as CustomizationPropsType),
          [CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS]:
            data[CustomizationFormProperties.BACKGROUND_TYPE] ===
              BackgroundType.Gradient &&
            gradientStartColor &&
            gradientEndColor
              ? [gradientStartColor, gradientEndColor]
              : undefined,
        }

        // 1. UPDATE MY PAGE STYLES
        const { error: updateStylesError } = await fetchWithBQ({
          path,
          method: 'put',
          data: stylesFormData,
        })

        if (updateStylesError) {
          return { error: updateStylesError }
        }

        // 2. HANDLE EDIT OF ABOUT ME FOR PAGE INFORMATION
        const editProfile = new FormData()
        const jsonData = JSON.stringify({
          name: name.trim(),
          about,
        })

        const blob = new Blob([jsonData], {
          type: 'application/json',
        })

        editProfile.set('body', blob)

        const { error: updateProfileError } = await fetchWithBQ({
          path: '/api/coach-profile-infos/update',
          method: 'put',
          data: editProfile,
        })

        if (updateProfileError) {
          return { error: updateProfileError }
        }

        // 3. HANDLE BACKGROUND IMAGE
        const backgroundType = (data as CustomizationPropsType)[
          CustomizationFormProperties.BACKGROUND_TYPE
        ]

        if (
          backgroundImage?.imageFile &&
          (backgroundType === BackgroundType.Image ||
            backgroundType === BackgroundType.Header)
        ) {
          await callApiEndpoint('setCreatorPageImage', _queryApi, {
            path: _links['self/edit-image'].href,
            name: ImageNamesEnum.BACKGROUND,
            file: backgroundImage.imageFile,
            stopInvalidatesTags: true,
          })
        } else if (
          backgroundType !== BackgroundType.Image &&
          backgroundType !== BackgroundType.Header
        ) {
          const deleteImage = _links['self/delete-image']?.find(
            (item: ImageResponseType) => item.name === ImageNamesEnum.BACKGROUND
          )

          if (deleteImage) {
            await callApiEndpoint('deleteCreatorPageImage', _queryApi, {
              path: deleteImage.href,
              stopInvalidatesTags: true,
            })
          }
        }

        // 4. HANDLE PROFILE IMAGE
        if (profileImageAction === 'upload' && profileImage?.imageFile) {
          await callApiEndpoint('setCreatorPageImage', _queryApi, {
            path: _links['self/edit-image'].href,
            name: ImageNamesEnum.DEFAULT,
            file: profileImage.imageFile,
            stopInvalidatesTags: true,
          })
        } else if (profileImageAction === 'delete') {
          const deleteImage = _links['self/delete-image']?.find(
            (item: ImageResponseType) =>
              item.name === ImageNamesEnum.DEFAULT ||
              item.name === ImageNamesEnum.AVATAR
          )

          if (deleteImage) {
            await callApiEndpoint('deleteCreatorPageImage', _queryApi, {
              path: deleteImage.href,
              stopInvalidatesTags: true,
            })
          }
        }

        // 5. Update page settings
        const { error: updateSettingsError } = await fetchWithBQ({
          path: `/creator-pages/${_links.self.id}/settings`,
          method: 'put',
          data: { show_sticky_gradient_footer: !hideFooterBanner },
        })

        if (updateSettingsError) return { error: updateSettingsError }

        // it was well succeeded
        return {
          data: {},
        }
      },
      invalidatesTags: (result, error, { profileImageAction }) =>
        result
          ? ([
              'CreatorDetails',
              'CreatorPage',
              profileImageAction !== 'none' ? 'UserAccount' : undefined,
            ].filter(Boolean) as [])
          : [],
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    importProfile: builder.mutation<string, any>({
      query: (profile) => ({
        path: `/creator-page-imports`,
        method: 'post',
        data: profile,
      }),
      transformResponse: (data: ImportProfileResponseType) =>
        data._links.self.href,
    }),
    getAffiliateLink: builder.query<
      AffiliateLinkType,
      { linkId: string; pageId?: string }
    >({
      query: ({ pageId, linkId }) => ({
        path: pageId
          ? `/creator-pages/${pageId}/affiliate-links/${linkId}`
          : `/views/public/affiliate-links/${linkId}`,
        method: 'get',
      }),
      transformResponse: fromAffiliateLinkAPI,
    }),
    getPublicAffiliateLink: builder.query<
      PublicAffiliateLinkType,
      { linkId: string; pageId?: string }
    >({
      query: ({ linkId }) => ({
        path: `/views/public/affiliate-links/${linkId}`,
        method: 'get',
      }),
      transformResponse: fromPublicAffiliateLinkAPI,
    }),
  }),
})

export type CreatorAPIEndpointsType = typeof creatorAPI.endpoints

export const {
  useGetCreatorQuery,
  useGetCreatorPageQuery,
  useGetCreatorPageSimpleAnalyticsQuery,
  useGetAffiliateLinkQuery,
  useGetPublicAffiliateLinkQuery,
  useUpdateCreatorPageSocialLinksMutation,
  useGetCreatorPageCommerceSettingsQuery,
  useUpdateCreatorPageCommerceSettingsMutation,
  useUpdateCreatorPageAppearanceMutation,
  useAddCreatorPageSectionAtomicMutation,
  useEditCreatorPageSectionAtomicMutation,
  useImportProfileMutation,
  useGetCreatorPageAffiliateLinksAnalyticsQuery,
  useGetCreatorPageAffiliateAnalyticsQuery,
  useGetAffiliateLinkAnalyticsQuery,
  useGetCreatorPageSimpleAnalyticsAggregationQuery,
  useAddCreatorPageSectionFromTrendingProductAtomicMutation,
} = creatorAPI
