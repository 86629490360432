import { useEffect, useState } from 'react'

import { Avatar, Flex, Icon, RichTextHtml } from '@web-apps/ui-shared'
import { emptyFunctionForOptionals } from '@web-apps/utils-shared'
import {
  StyledHeader,
  StyledName,
  StyledShareButtonContainer,
} from './Header.styles'
import { SocialLinks } from '../SocialLinks'
import { computeAvatarUrl } from './Header.utils'

import {
  CreatorPageType,
  CustomizationFormProperties,
  SocialLinkType,
  SocialsPlacement,
} from '@web-apps/utils-types'

type Props = {
  creatorPage: CreatorPageType
  socialLinkTracker?: (socialLink: SocialLinkType) => void
  enableShareFeature: boolean
  onShareClick?: () => void
}

export const Header = ({
  creatorPage,
  socialLinkTracker,
  enableShareFeature,
  onShareClick = emptyFunctionForOptionals,
}: Props) => {
  const placement =
    creatorPage.styles[CustomizationFormProperties.SOCIALS_PLACEMENT]
  const showSocials = !placement || placement === SocialsPlacement.TOP

  /* for slow connections the share button will be visible because it exists on generated html,
   * this adds a check to only show the button when javascript is loaded
   */
  const [javascriptIsLoaded, setJavascriptIsLoaded] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') setJavascriptIsLoaded(true)
  }, [])

  return (
    <StyledHeader>
      <Flex shrink={0}>
        <Avatar
          url={computeAvatarUrl({ creatorPage })}
          name={creatorPage.name}
          shape={
            creatorPage.styles[CustomizationFormProperties.PROFILE_IMAGE_SHAPE]
          }
        />
      </Flex>
      <div>
        <StyledName data-cy="creator-name">{creatorPage.name}</StyledName>
        {creatorPage.tagline && (
          <RichTextHtml
            data-cy="creator-description"
            htmlText={creatorPage.tagline}
          />
        )}
        {showSocials && (
          <SocialLinks
            links={creatorPage.social_links}
            socialLinkTracker={socialLinkTracker}
          />
        )}
      </div>

      {enableShareFeature && javascriptIsLoaded && (
        <StyledShareButtonContainer
          as="button"
          onClick={() => {
            onShareClick()
          }}
        >
          <Icon.Share
            fillColor={
              creatorPage.styles[CustomizationFormProperties.BUTTONS_TEXT_COLOR]
            }
          />
        </StyledShareButtonContainer>
      )}
    </StyledHeader>
  )
}
