import { SectionLinkAdapterInterface } from '.'
import { CustomLinkProps } from '../components'

import { baseCustomLinkPropsFromSection } from './sectionLinks.adapter'

import { ExternalLinkMode, SectionTypeEnum } from '@web-apps/utils-types'
import { isLinkUnsafe } from '../utils'

/* This code was stolen from the next/router package */
const normalizeRepeatedSlashes = (url: string) => {
  // repeated slashes and backslashes in the URL are considered
  // invalid and will never match a Next.js page/file
  const urlProtoMatch = url.match(/^[a-zA-Z]{1,}:\/\//)
  const urlAsStringNoProto = urlProtoMatch
    ? url.slice(urlProtoMatch[0].length)
    : url

  const urlParts = urlAsStringNoProto.split('?')
  const urlNoQuery = urlParts[0]

  const normalizedUrl =
    urlNoQuery
      // first we replace any non-encoded backslashes with forward
      // then normalize repeated forward slashes
      .replace(/\\/g, '/')
      .replace(/\/\/+/g, '/') +
    (urlParts[1] ? `?${urlParts.slice(1).join('?')}` : '')

  return (urlProtoMatch ? urlProtoMatch[0] : '') + normalizedUrl
}

export const plainExternalLinkPropsFromSection: SectionLinkAdapterInterface = (
  section,
  configs
) => {
  if (section.type !== SectionTypeEnum.EXTERNAL_LINK)
    throw new Error('Invalid section type')
  if (section.mode !== ExternalLinkMode.LINK)
    throw new Error('Invalid Plain Link')

  const baseInfo = baseCustomLinkPropsFromSection(section, configs)

  const plainExternalLinkProps: CustomLinkProps = {
    ...baseInfo,
    href:
      (section._links.alternate?.href &&
        normalizeRepeatedSlashes(section._links.alternate.href)) ||
      (section._links.about?.href &&
        normalizeRepeatedSlashes(section._links.about.href)),
    isExternalHref: true,
    rel: section._links.alternate?.href ? undefined : 'noreferrer',
    cyTag: `creator-external-link-${baseInfo.id}`,
    isRestricted: isLinkUnsafe(section._links.about?.href),
    sectionHref: section._links.self.href,
  }

  return plainExternalLinkProps
}
