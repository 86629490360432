import { FC, PropsWithChildren, useState } from 'react'
import { Box, Flex, Icon } from '@web-apps/ui-shared'
import { StyledContainer, StyledRemoveButton } from './AccordionWrapper.styles'
import {
  ExternalLinkMode,
  SectionExtension,
  SectionType,
  SectionTypeEnum,
  SectionVariantEnum,
} from '@web-apps/utils-types'
import { AccordionAnimation, AnimationStateEnum } from './AccordionAnimation'
import { getSectionImage } from '../../utils'
import { CustomLink } from '../CustomLink'

type Props = {
  defaultLabel: string
  section: SectionType
}

export const AccordionWrapper: FC<PropsWithChildren<Props>> = ({
  section,
  defaultLabel,
  children,
}) => {
  const [contentState, setContentState] = useState(AnimationStateEnum.HIDDEN)
  const [buttonState, setButtonState] = useState(AnimationStateEnum.INITIAL)

  if (section.styles.extension !== SectionExtension.ACCORDION) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  const isCover = section.styles.variant === SectionVariantEnum.COVER

  return (
    <StyledContainer>
      <AccordionAnimation isButton state={buttonState}>
        <CustomLink
          hasChevron
          id={section._links.self.id}
          label={section.label || defaultLabel}
          tagline={section.tagline}
          image={getSectionImage(section)}
          styles={section.styles}
          onClick={(e) => {
            e.preventDefault()
            setContentState(AnimationStateEnum.SHOWN)
            setButtonState(AnimationStateEnum.HIDDEN)
          }}
          skipImageErrorHandler
          style={isCover ? { padding: '24px 12px' } : {}}
          contentStyles={
            isCover ? { padding: '8px 16px 8px 64px', textAlign: 'center' } : {}
          }
        />
      </AccordionAnimation>
      <AccordionAnimation
        state={contentState}
        overflow={
          section.type === SectionTypeEnum.CONTAINER ||
          section.mode === ExternalLinkMode.EMBED
            ? 'hidden'
            : 'initial'
        }
      >
        <Box mt={3}>{children}</Box>
        <Flex justify="center">
          <StyledRemoveButton
            onClick={() => {
              setContentState(AnimationStateEnum.HIDDEN)
              setButtonState(AnimationStateEnum.SHOWN)
            }}
          >
            <Icon.PlainRemove width={24} height={24} />
          </StyledRemoveButton>
        </Flex>
      </AccordionAnimation>
    </StyledContainer>
  )
}
