import styled, { css } from 'styled-components'
import { Theme } from '@web-apps/ui-shared'

export const StyledFooterBanner = styled.div<{ $isInstagram?: boolean }>`
  z-index: ${Theme.CreatorPage.footer.zIndex};

  ${({ $isInstagram }) =>
    $isInstagram
      ? css`
          display: flex;
          justify-content: center;
          position: relative;
        `
      : css`
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
        `}

  bottom: 0;
  margin-bottom: 20px;
`

export const StyledFooterBannerWrapper = styled.a`
  white-space: nowrap;

  display: flex;
  gap: 16px;
  align-items: center;

  padding: 8px 16px;

  border-radius: 48px;
  border: 1px solid rgba(255, 255, 255, 0.48);
  background: #111111;
`

export const StyledFooterContainer = styled.div`
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  color: ${Theme.Colors.typography.inverse};

  display: flex;
  justify-content: center;
  gap: 8px;

  &:hover {
    color: ${Theme.Colors.typography.inverse};
  }
`
