import { useState } from 'react'
import {
  imageUrlBuilder,
  isImageFromZezamStorage,
} from '@web-apps/utils-shared'
import { ImageResponseType } from '@web-apps/utils-types'
import { StyledImage } from './ImageBlock.styles'

type Props = {
  image?: ImageResponseType
  alt?: string
  skipErrorHandler?: boolean
}

export const ImageBlock = ({ image, alt, skipErrorHandler }: Props) => {
  const [hideImage, setHideImage] = useState(false)
  const [errorIsHandled, setErrorIsHandled] = useState(skipErrorHandler)

  if (image && !hideImage) {
    return (
      <StyledImage
        alt={alt}
        src={imageUrlBuilder({ image, width: 96, height: 96 })}
        onError={({ currentTarget }) => {
          if (isImageFromZezamStorage(image.href) || skipErrorHandler) {
            setHideImage(true)
            return
          }

          if (!errorIsHandled) {
            currentTarget.onerror = null
            setErrorIsHandled(true)
            currentTarget.src = `/api/proxy?url=${encodeURIComponent(
              image.href
            )}`
          }
        }}
      />
    )
  }

  return null
}
