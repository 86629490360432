import Image from 'next/image'
import {
  imageLoader,
  imageUrlBuilder,
  isImageOptimized,
} from '@web-apps/utils-shared'

import {
  BackgroundInfo,
  BackgroundType,
  BackgroundTypeStyle,
  ImageResponseType,
} from '@web-apps/utils-types'
import styled from 'styled-components'

type Props = {
  image?: ImageResponseType
  backgroundInfo: BackgroundInfo
  isPreview?: boolean
}

/* The style for it is explained here: https://gitlab.com/zezam/frontend/web-apps/-/issues/777 */
const StyledBlurredImage = styled(Image)`
  left: -10% !important;
  min-width: 120% !important;
  max-width: 120% !important;
  min-height: 120% !important;
  max-height: 120% !important;
  filter: blur(24px);
`

export const BackgroundImage = ({
  image,
  backgroundInfo,
  isPreview,
}: Props) => {
  if (!image || !image?.href) return null

  const isOptimized = isImageOptimized(image)
  const applyBlurEffect =
    backgroundInfo.backgroundType === BackgroundType.Image &&
    (backgroundInfo.backgroundStyle === BackgroundTypeStyle.BLURRED_DARK ||
      backgroundInfo.backgroundStyle === BackgroundTypeStyle.BLURRED_LIGHT)

  const ImageComponent = applyBlurEffect ? StyledBlurredImage : Image

  return (
    <ImageComponent
      src={
        isPreview
          ? (imageUrlBuilder({ image, width: 700 }) as string)
          : image.href
      }
      data-testid="background-image"
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      priority
      loader={isOptimized ? imageLoader : undefined}
      unoptimized={!isOptimized || isPreview}
    />
  )
}
