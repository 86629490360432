import { Theme } from '@web-apps/ui-shared'
import styled from 'styled-components'

import {
  BackgroundType,
  CustomizationFormProperties,
} from '@web-apps/utils-types'

export const StyledFooterContainer = styled.footer<{ $hide: boolean }>`
  display: flex;
  height: ${Theme.CreatorPage.footer.height}px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));

  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  background: ${({ theme }) =>
    theme?.customization &&
    theme.customization[CustomizationFormProperties.BACKGROUND_TYPE] ===
      BackgroundType.Solid
      ? theme.customization[CustomizationFormProperties.BACKGROUND]
      : 'initial'};
  color: ${({ theme }) =>
    `${
      (theme.customization &&
        theme.customization[CustomizationFormProperties.TEXT]) ||
      Theme.CreatorPage.footer.defaultTextColor
    }${Theme.CreatorPage.footer.hexAlpha}`};

  path {
    fill: ${({ theme }) =>
      `${
        (theme.customization &&
          theme.customization[CustomizationFormProperties.TEXT]) ||
        Theme.CreatorPage.footer.defaultTextColor
      }${Theme.CreatorPage.footer.hexAlpha}`};
  }
`

export const StyledLink = styled.a`
  width: 100%;

  &,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }
`

export const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
`
