import { plainExternalLinkPropsFromSection } from './plainExternalLink.adapter'
import { affiliateLinkPropsFromSection } from './affiliateLinkSection.adapter'

import {
  CreatorPageMessagesType,
  ExternalLinkMode,
  LinkableSectionType,
  LinkableSectionTypeValidValues,
  LINKABLE_SECTION_TYPES,
  ProductRecommendationsSectionType,
  SectionType,
  SectionTypeEnum,
} from '@web-apps/utils-types'
import { CustomLinkProps } from '../components'

export type SectionLinkAdapterConfigs = {
  messages?: CreatorPageMessagesType
  useRelativePaths?: boolean
  context?: {
    containerSection?: ProductRecommendationsSectionType
  }
}

export type SectionLinkAdapterInterface = (
  section: LinkableSectionType,
  configs: SectionLinkAdapterConfigs
) => CustomLinkProps | null

const SectionLinkAdaptersBySectionType: {
  [key in LinkableSectionTypeValidValues]: SectionLinkAdapterInterface
} = {
  [SectionTypeEnum.EXTERNAL_LINK]: plainExternalLinkPropsFromSection,
  [SectionTypeEnum.AFFILIATE_LINK]: affiliateLinkPropsFromSection,
}

// whitelisting section types that could be linked
export const isLinkableSection = (section: SectionType) => {
  return LINKABLE_SECTION_TYPES.includes(section.type)
}

// check if section could be a custom link
export const isCustomLinkSection = (section: SectionType) => {
  if (!isLinkableSection(section)) return false

  return !(
    section.type === SectionTypeEnum.EXTERNAL_LINK &&
    section.mode === ExternalLinkMode.EMBED
  )
}

export const sectionLinkAdapterFacade: SectionLinkAdapterInterface = (
  section,
  configs
) => {
  try {
    return isCustomLinkSection(section)
      ? SectionLinkAdaptersBySectionType[section.type](section, configs)
      : null
  } catch (e) {
    return null
  }
}
