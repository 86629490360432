import styled, { css } from 'styled-components'

import { TextEllipsis, Theme } from '@web-apps/ui-shared'

import {
  borderHandler,
  StyledSectionContainer,
  StyledSectionContainerProps,
  StyledSectionTitle,
} from '../Section.styles'

import {
  ButtonShapeStyle,
  ButtonThumbnailPositionStyle,
  CustomizationFormProperties,
  SectionCoverFormat,
  SectionVariantEnum,
} from '@web-apps/utils-types'

type StyledCustomLinkProps = StyledSectionContainerProps & {
  $variant?: SectionVariantEnum
  $imageUrl?: string
  $hasLeftImage?: boolean
}

const BORDER_RADIUS_IN_PX_FOR_COVER_SECTION_WHEN_PILL_BUTTONS = 32

const COVER_ASPECT_RATIO: { [key in SectionCoverFormat]: string } = {
  [SectionCoverFormat.LANDSCAPE]: '16 / 9',
  [SectionCoverFormat.SQUARE]: '1',
  [SectionCoverFormat.PORTRAIT]: '4 / 5',
}
const COVER_ASPECT_RATIO_FALLBACK: { [key in SectionCoverFormat]: string } = {
  [SectionCoverFormat.LANDSCAPE]: '56%',
  [SectionCoverFormat.SQUARE]: '100%',
  [SectionCoverFormat.PORTRAIT]: '125%',
}

/* Empty to be able to nest className below */
export const StyledSectionTitleForNestedSection = styled(TextEllipsis)``

export const StyledCustomLink = styled(
  StyledSectionContainer
)<StyledCustomLinkProps>`
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  ${({ $textColor, theme }) => css`
    &:hover,
    &:active {
      text-decoration: none;
      color: ${$textColor ||
      theme.customization[CustomizationFormProperties.BUTTONS_TEXT_COLOR]};
    }
  `}

  ${({ theme, $hasLeftImage, $variant }) => {
    const thumbnailPosition =
      theme.customization[
        CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION
      ]

    return (
      (thumbnailPosition === ButtonThumbnailPositionStyle.LEADING ||
        thumbnailPosition === ButtonThumbnailPositionStyle.STICK_OUT) &&
      $variant !== SectionVariantEnum.COVER &&
      $hasLeftImage &&
      css`
        margin-left: 16px;
        width: calc(100% - 16px);
      `
    )
  }}

  ${({ $variant, $textColor, $imageUrl, theme, $isNested, $coverFormat }) => {
    const aspectRatio =
      COVER_ASPECT_RATIO[$coverFormat || SectionCoverFormat.LANDSCAPE]
    const aspectRatioFallback =
      COVER_ASPECT_RATIO_FALLBACK[$coverFormat || SectionCoverFormat.LANDSCAPE]
    switch ($variant) {
      case SectionVariantEnum.COVER:
        return css`
          ${$isNested
            ? `
                /* old browsers compatible solution: https://css-tricks.com/almanac/properties/a/aspect-ratio/#aa-dealing-with-legacy-browser-support */
                @supports not (aspect-ratio: 1) {
                  &::before {
                    float: left;
                    padding-top: 100%;
                    content: "";
                  }

                  &::after {
                    display: block;
                    content: "";
                    clear: both;
                  }
                }

                @supports (aspect-ratio: 1) {
                  aspect-ratio: 1;
                }
              `
            : `
              @supports not (aspect-ratio: ${aspectRatioFallback}) {
                &::before {
                  float: left;
                  padding-top: ${aspectRatioFallback};
                  content: "";
                }

                &::after {
                  display: block;
                  content: "";
                  clear: both;
                }
              }

              @supports (aspect-ratio: ${aspectRatio}) {
                aspect-ratio: ${aspectRatio};
              }
            `}
          align-items: flex-end;
          ${theme.customization[CustomizationFormProperties.BUTTONS_SHAPE] ===
          ButtonShapeStyle.PILL
            ? `border-radius: ${BORDER_RADIUS_IN_PX_FOR_COVER_SECTION_WHEN_PILL_BUTTONS}px;`
            : ''}
          padding: ${$isNested ? '0' : '24px'};
          color: ${$textColor || Theme.CreatorPage.customLink.coverTextColor};
          background: ${!$isNested
              ? `linear-gradient(
            180deg,
            rgba(51, 51, 51, 0) 0.23%,
            #333 99.77%
          ),`
              : ''}
            url('${$imageUrl}');
          background-size: cover;
          background-position: center;
          background-blend-mode: multiply;

          &:hover {
            color: ${$textColor || Theme.CreatorPage.customLink.coverTextColor};
          }

          ${StyledSectionTitleForNestedSection} {
            color: ${$textColor || Theme.CreatorPage.customLink.coverTextColor};
          }
        `
      case SectionVariantEnum.IMAGE:
      case SectionVariantEnum.PLAIN:
      default:
        return css`
          padding: ${$isNested ? '0' : '8px'};
        `
    }
  }}
`

export const StyledContent = styled.div<{
  $hasLeftContent?: boolean
  $hasRightContent?: boolean
  $isNested?: boolean
  $hasLabel?: boolean
  $variant?: SectionVariantEnum
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: ${({ $hasLeftContent, $hasRightContent, $variant, theme }) => {
    const thumbnailPosition =
      theme.customization[
        CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION
      ]
    if (
      thumbnailPosition === ButtonThumbnailPositionStyle.STICK_OUT &&
      $hasLeftContent &&
      !$hasRightContent
    ) {
      return '8px 48px'
    }

    if (
      thumbnailPosition === ButtonThumbnailPositionStyle.STICK_OUT &&
      $hasLeftContent &&
      $hasRightContent
    ) {
      return '8px 8px 8px 48px'
    }

    if ($hasRightContent && $variant === SectionVariantEnum.COVER) {
      return '8px 16px 8px 0px'
    }

    if (!$hasLeftContent && $hasRightContent) {
      return '8px 16px 8px 64px'
    }

    if (!$hasRightContent && $hasLeftContent) {
      return '8px 64px 8px 16px'
    }

    return '8px 16px'
  }};
  width: 100%;
  position: relative;
  text-align: ${({ $hasRightContent, $variant }) =>
    $hasRightContent && $variant === SectionVariantEnum.COVER
      ? 'left'
      : 'center'};

  ${(props) =>
    props.$isNested && props.$hasLabel
      ? css`
          border-radius: ${props.$variant === SectionVariantEnum.COVER &&
          props.theme.customization[
            CustomizationFormProperties.BUTTONS_SHAPE
          ] === ButtonShapeStyle.PILL
            ? `${BORDER_RADIUS_IN_PX_FOR_COVER_SECTION_WHEN_PILL_BUTTONS}px`
            : borderHandler(props)};
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background: ${props.$variant === SectionVariantEnum.COVER
            ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)`
            : 'none'};
        `
      : ''}
`

export const StyledPrice = styled(StyledSectionTitle)<{ $textColor?: string }>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  padding: 4px;
  height: 48px;
  border-radius: 24px;
  white-space: nowrap;
  background-color: ${({ $textColor, theme }) =>
    `${
      $textColor ||
      theme.customization[CustomizationFormProperties.BUTTONS_TEXT_COLOR]
    }1a`};
`

export const StyledChevronWrapper = styled(StyledSectionTitle)<{
  $textColor?: string
  $variant?: SectionVariantEnum
}>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  padding: 4px;
  height: 48px;
  border-radius: 24px;
  white-space: nowrap;
  background-color: ${({ $variant }) =>
    $variant === SectionVariantEnum.COVER
      ? `${Theme.CreatorPage.customLink.coverTextColor}33`
      : 'none'};

  > svg,
  path {
    fill: ${({ $textColor, $variant, theme }) =>
      $textColor || $variant === SectionVariantEnum.COVER
        ? Theme.CreatorPage.customLink.coverTextColor
        : theme.customization[CustomizationFormProperties.BUTTONS_TEXT_COLOR]};
  }
`
