import styled, { css } from 'styled-components'

import {
  ButtonBorderStyle,
  ButtonShapeStyle,
  CustomizationFormProperties,
  ButtonShadowStyle,
  FontEnum,
  ColorOpacity,
  SectionCoverFormat,
} from '@web-apps/utils-types'

export type StyledSectionContainerProps = {
  $textColor?: string
  $backgroundColor?: string
  $borderStyle?: ButtonBorderStyle
  $isNested?: boolean
  $coverFormat?: SectionCoverFormat
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const borderHandler = ({ theme }: { theme: any }) => {
  switch (theme.customization[CustomizationFormProperties.BUTTONS_SHAPE]) {
    case ButtonShapeStyle.ROUNDED:
      return '8px'
    case ButtonShapeStyle.PILL:
      return '32px'
    case ButtonShapeStyle.SQUARE:
    default:
      return '0'
  }
}

export const StyledSectionContainer = styled.div<StyledSectionContainerProps>`
  position: relative;
  width: 100%;
  min-height: 64px;

  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);
    }
  }

  color: ${({ $textColor, theme }) =>
    $textColor ||
    theme.customization[CustomizationFormProperties.BUTTONS_TEXT_COLOR]};

  border-radius: ${borderHandler};

  box-shadow: ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.BUTTONS_SHADOW]) {
      case ButtonShadowStyle.SOFT:
        return `0px 2px 4px ${
          theme.customization[CustomizationFormProperties.BUTTONS_SHADOW_COLOR]
        }${
          theme.customization[
            CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
          ]
        }`
      case ButtonShadowStyle.HARD:
        return `1px 2px 0px ${
          theme.customization[CustomizationFormProperties.BUTTONS_SHADOW_COLOR]
        }${
          theme.customization[
            CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
          ]
        }`
      default:
        return 'none'
    }
  }};

  border: none;

  ${({ $backgroundColor, $borderStyle, theme }) => {
    switch (
      $borderStyle ||
      theme.customization[CustomizationFormProperties.BUTTONS_BORDER_STYLE]
    ) {
      case ButtonBorderStyle.OUTLINE:
        return css`
          border: 1px solid
            ${theme.customization[
              CustomizationFormProperties.BUTTONS_BORDER_COLOR
            ] || 'rgba(51, 51, 51, 0.8)'};
          background-color: transparent;
        `
      case ButtonBorderStyle.FILLED:
      default:
        return css`
          background-color: ${
            $backgroundColor ||
            `${
              theme.customization[
                CustomizationFormProperties.BUTTONS_BACKGROUND_COLOR
              ]
            }${
              theme.customization[
                CustomizationFormProperties.BUTTONS_BACKGROUND_OPACITY
              ] || ''
            }`
          }
        }

        ;

        ${
          theme.customization[
            CustomizationFormProperties.BUTTONS_BACKGROUND_OPACITY
          ] !== ColorOpacity['100%'] && 'backdrop-filter: blur(4px);'
        }

        `
    }
  }}
`

export const StyledSectionTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: inherit;
  word-break: break-word;
  margin-bottom: 0;

  ${({ theme }) => {
    return theme?.customization?.[CustomizationFormProperties.FONT] ===
      FontEnum.AMATIC_SC
      ? 'font-size: 16px;'
      : ''
  }}
`
