import styled, { css } from 'styled-components'
import { StyledSectionContainer } from '../Section.styles'

import { Theme } from '@web-apps/ui-shared'

import {
  HeaderPosition,
  CustomizationFormProperties,
  FontEnum,
  BackgroundType,
  ButtonBorderStyle,
} from '@web-apps/utils-types'

export const StyledHeader = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;
  ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.HEADER_POSITION]) {
      case HeaderPosition.CENTER:
        return css`
          flex-direction: column;
          text-align: center;
          padding-top: 32px;
        `
      case HeaderPosition.LEFT:
      default:
        return css`
          flex-direction: row;
          padding-top: 36px;
        `
    }
  }}
  ${({ theme }) => {
    if (
      theme.customization[CustomizationFormProperties.BACKGROUND_TYPE] ===
      BackgroundType.Header
    )
      return css`
        padding-top: 0;
      `
    return ''
  }}
`

export const StyledName = styled.h5`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;

  ${({ theme }) => {
    return theme?.customization?.[CustomizationFormProperties.FONT] ===
      FontEnum.AMATIC_SC
      ? css`
          font-size: 20px;
        `
      : ''
  }}
`

export const StyledShareButtonContainer = styled(StyledSectionContainer)`
  min-height: revert;
  width: revert;

  position: absolute;
  right: 0;
  top: 0;

  ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.BACKGROUND_TYPE]) {
      case BackgroundType.Header:
        return css`
          top: -${152 - 16}px;
        `
      default:
        return css`
          top: 0;
        `
    }
  }}

  ${({ theme }) => {
    if (
      theme.customization[CustomizationFormProperties.BUTTONS_BORDER_STYLE] ===
      ButtonBorderStyle.OUTLINE
    )
      return `
        background-color: ${Theme.Colors.background.halfTransparent};
        box-shadow: 0 0 0 8px ${Theme.Colors.background.halfTransparent};
      `
    return ''
  }}

  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: auto;
  margin-block-end: auto;

  padding: 8px;
  border-radius: 50%;
`
