import { Box, RichTextHtml } from '@web-apps/ui-shared'

import { ContentSectionType, SectionExtension } from '@web-apps/utils-types'
import { StyledSectionContainer, StyledSectionTitle } from '../Section.styles'

type Props = {
  data: ContentSectionType
}

export const ContentSection = ({ data }: Props) => {
  const isExpandable = data.styles.extension === SectionExtension.ACCORDION

  return isExpandable ? (
    <StyledSectionContainer style={{ textAlign: 'center' }}>
      <Box p={16}>
        <Box mb={16}>
          <StyledSectionTitle>{data.label}</StyledSectionTitle>
        </Box>
        <RichTextHtml
          htmlText={data.text}
          style={{ fontSize: '14px', lineHeight: '24px' }}
        />
      </Box>
    </StyledSectionContainer>
  ) : (
    <RichTextHtml
      htmlText={data.text}
      style={{ textAlign: 'center', fontSize: '14px', lineHeight: '24px' }}
    />
  )
}
