import styled from 'styled-components'

import { CustomizationFormProperties } from '@web-apps/utils-types'

export const StyledDescription = styled.div<{ $textColor?: string }>`
  font-size: 12px;
  color: inherit;
  text-align: inherit;
  word-break: break-word;

  svg {
    flex-shrink: 0;
    margin-right: 4px;

    path {
      stroke: ${({ $textColor, theme }) =>
        $textColor ||
        theme.customization[CustomizationFormProperties.BUTTONS_TEXT_COLOR]};
    }
  }
`
