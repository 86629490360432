import React, { useContext, useEffect, useState } from 'react'
import {
  getEmbedData,
  EmbedDataType,
  loadIframelyEmbedJS,
} from '@web-apps/utils-shared'
import {
  StyledContainer,
  StyledTikTokContainer,
} from './IframelyEmbedLink.styles'

type Props = {
  url: string
  isTikTok?: boolean
}

export const EmbedContext = React.createContext({ isPreview: false })

export const IframelyEmbedLink = ({ url, isTikTok }: Props) => {
  const [data, setData] = useState<EmbedDataType | null>(null)
  const { isPreview } = useContext(EmbedContext)

  useEffect(() => {
    if (url) {
      getEmbedData(url).then((data) => {
        setData(data)
      })
    }
  }, [url])

  useEffect(() => {
    if (data?.html) {
      loadIframelyEmbedJS()
    }
  }, [data])

  if (!data?.html) {
    return null
  }

  if (isTikTok) {
    return (
      <StyledTikTokContainer $isPreview={isPreview}>
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
      </StyledTikTokContainer>
    )
  }

  return (
    <StyledContainer>
      <div dangerouslySetInnerHTML={{ __html: data.html }} />
    </StyledContainer>
  )
}
