// We need to use a custom method because SSR does not support window.atob
import { atob } from 'abab'
import { ExtractedColors } from '@web-apps/utils-shared'
import { getImageByName } from './image.utils'

import {
  BackgroundInfo,
  BackgroundType,
  BackgroundTypeStyle,
  ButtonBorderStyle,
  ColorOpacity,
  ButtonShadowStyle,
  ButtonShapeStyle,
  ButtonThumbnailPositionStyle,
  CreatorPageType,
  CustomizationFormProperties,
  CustomizationPropsType,
  HeaderPosition,
  FontEnum,
  ImageNamesEnum,
  ProfileImageShape,
} from '@web-apps/utils-types'

export const LEGACY_AFFILIATE_LINKS = 'legacy'
export const colorsInitialValues = {
  [CustomizationFormProperties.BACKGROUND]: '#FFFFFF',
  [CustomizationFormProperties.TEXT]: '#333333',
  [CustomizationFormProperties.BUTTONS_BACKGROUND_COLOR]: '#EFEFEF',
  [CustomizationFormProperties.BUTTONS_TEXT_COLOR]: '#333333',
  [CustomizationFormProperties.BUTTONS_BORDER_COLOR]: '#333333',
  [CustomizationFormProperties.BUTTONS_SHADOW_COLOR]: '#333333',
  [CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS]: [
    '#D7E2E4',
    '#000000',
  ] as [string, string],
}

export const initialValuesForCustomization: CustomizationPropsType = {
  [CustomizationFormProperties.FONT]: FontEnum.INTER,
  [CustomizationFormProperties.HEADER_POSITION]: HeaderPosition.CENTER,
  [CustomizationFormProperties.BUTTONS_SHAPE]: ButtonShapeStyle.PILL,
  [CustomizationFormProperties.BUTTONS_BORDER_STYLE]: ButtonBorderStyle.FILLED,
  [CustomizationFormProperties.BUTTONS_SHADOW]: ButtonShadowStyle.NO_SHADOW,
  [CustomizationFormProperties.BUTTONS_SHADOW_OPACITY]: ColorOpacity['60%'],
  [CustomizationFormProperties.BUTTONS_BACKGROUND_OPACITY]:
    ColorOpacity['100%'],
  [CustomizationFormProperties.BACKGROUND_TYPE_STYLE]:
    BackgroundTypeStyle.REGULAR,
  [CustomizationFormProperties.BACKGROUND_TYPE]: BackgroundType.Solid,
  [CustomizationFormProperties.PROFILE_IMAGE_SHAPE]: ProfileImageShape.CIRCLE,
  [CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION]:
    ButtonThumbnailPositionStyle.REGULAR,
  ...colorsInitialValues,
}

export const computeBackgroundInfoForStyles = (
  creatorPage: Omit<CreatorPageType, 'styles'> & {
    styles?: CustomizationPropsType
  }
): BackgroundInfo => {
  const styles = { ...creatorPage.styles }
  const backgroundSolidInfo = {
    backgroundType: BackgroundType.Solid as BackgroundType.Solid,
    backgroundColor:
      styles[CustomizationFormProperties.BACKGROUND] ||
      initialValuesForCustomization[CustomizationFormProperties.BACKGROUND],
  }

  if (!styles) return backgroundSolidInfo

  const image = getImageByName(
    creatorPage._links['self/image'],
    ImageNamesEnum.BACKGROUND
  )

  switch (styles[CustomizationFormProperties.BACKGROUND_TYPE]) {
    case BackgroundType.Image: {
      return {
        backgroundType: BackgroundType.Image,
        backgroundStyle:
          styles[CustomizationFormProperties.BACKGROUND_TYPE_STYLE] ||
          BackgroundTypeStyle.REGULAR,
        image,
      }
    }
    case BackgroundType.Header: {
      return {
        backgroundType: BackgroundType.Header,
        backgroundStyle:
          styles[CustomizationFormProperties.BACKGROUND_TYPE_STYLE] ||
          BackgroundTypeStyle.REGULAR,
        backgroundColor: backgroundSolidInfo.backgroundColor,
        image,
      }
    }
    case BackgroundType.Gradient:
      return {
        backgroundType: BackgroundType.Gradient,
        gradientColors:
          styles[CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS] ||
          colorsInitialValues[
            CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS
          ],
      }
    case BackgroundType.Solid:
      return backgroundSolidInfo
    default: {
      // For users with incorrect backgroundType property
      if (image) {
        return {
          backgroundType: BackgroundType.Image,
          backgroundStyle:
            styles[CustomizationFormProperties.BACKGROUND_TYPE_STYLE] ||
            BackgroundTypeStyle.REGULAR,
          image,
        }
      }

      return backgroundSolidInfo
    }
  }
}

// The list of restricted words is decoded to base64 so that it never present
// on the page
const RESTRICTED_RESOURCES_KEYWORDS = [
  'b25seWZhbnM=',
  'cG9ybg==',
  'eHh4',
  'a2luay5jb20=',
  'eW91aml6eg==',
  'cmVkdHViZQ==',
  'Y2hhdHVyYmF0ZQ==',
  'Ym9uZ2FjYW1z',
  'bXlmcmVlY2Ftcw==',
  'bGl2ZWphc21pbg==',
  'c2x1dHJvdWxldHRl',
  'eHZpZGVvcy5jb20=',
  'eG54eC5jb20=',
  'ZmFuc2x5LmNvbQ==',
  'YmVzdGZhbnMuY29t',
  'Y2VudGVyZm9sZC5jb20=',
  'ZmFuc2V2ZW4uY29t',
]

export const isLinkUnsafe = (URL?: string): boolean => {
  if (!URL) return false

  let containsRestrictedWord = false
  const formattedURL = URL.toLowerCase()
  RESTRICTED_RESOURCES_KEYWORDS.every((keyword) => {
    const encodedKeyword = atob(keyword) as string
    containsRestrictedWord = formattedURL.includes(encodedKeyword)
    return !containsRestrictedWord
  })
  return containsRestrictedWord
}

export const fallbackColorsForGetStarted: Omit<
  ExtractedColors,
  'dominantColor'
> = {
  paletteColors: [
    {
      hexValue: '#3050EC',
    },
    {
      hexValue: '#BC7AFF',
    },
    {
      hexValue: '#FBFF33',
    },
  ],
}
