import { ThemeProvider } from 'styled-components'

import { CustomizationPropsType } from '@web-apps/utils-types'
import { initialValuesForCustomization } from '../utils'

export const CreatorThemeWrapper: React.FC<
  React.PropsWithChildren<{
    customizationProps?: CustomizationPropsType
    extraPadding?: number
  }>
> = ({ customizationProps, extraPadding, children }) => {
  return (
    <ThemeProvider
      theme={{
        customization:
          customizationProps && Object.keys(customizationProps).length > 0
            ? customizationProps
            : initialValuesForCustomization,
        extraPadding,
      }}
    >
      {children}
    </ThemeProvider>
  )
}
