import React, { useState, CSSProperties } from 'react'

import { imageUrlBuilder } from '@web-apps/utils-shared'

import { Icon, ProceedToLinkPopUp, Typography } from '@web-apps/ui-shared'

import {
  StyledCustomLink,
  StyledContent,
  StyledPrice,
  StyledSectionTitleForNestedSection,
  StyledChevronWrapper,
} from './CustomLink.styles'
import { ImageBlock } from './ImageBlock'

import { StyledSectionTitle } from '../Section.styles'
import { TaglineBlock } from '../TaglineBlock'

import {
  SectionVariantEnum,
  ButtonBorderStyle,
  ImageResponseType,
  SectionBasePropertiesType,
} from '@web-apps/utils-types'

export type CustomLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  id: string
  label: string
  href?: string
  rel?: string
  sectionHref?: string
  isExternalHref?: boolean
  isRestricted?: boolean
  tagline?: string
  taglineIcon?: React.FC
  image?: ImageResponseType
  priceText?: string
  variant?: SectionVariantEnum
  styles?: SectionBasePropertiesType['styles']
  buttonBorderStyle?: ButtonBorderStyle
  backgroundColor?: string
  textColor?: string
  cyTag?: string
  skipImageErrorHandler?: boolean
  hideRestrictPopup?: boolean
  isNested?: boolean
  hasChevron?: boolean
  contentStyles?: CSSProperties
  translations?: {
    title: string
    description: string
    proceed: string
    cancel: string
  }
}

export const CustomLink = React.forwardRef<HTMLAnchorElement, CustomLinkProps>(
  (
    {
      id,
      label,
      href,
      rel,
      sectionHref,
      isExternalHref,
      tagline,
      taglineIcon,
      image,
      priceText,
      variant,
      styles,
      buttonBorderStyle,
      backgroundColor,
      textColor,
      cyTag = 'creator-custom-link',
      isRestricted,
      translations,
      hideRestrictPopup = false,
      skipImageErrorHandler,
      isNested = false,
      hasChevron,
      contentStyles = {},
      ...anchorProps
    },
    ref
  ) => {
    const hasImageBlock = Boolean(
      image?.href && styles?.variant === SectionVariantEnum.IMAGE
    )
    const backgroundImageUrl = image && imageUrlBuilder({ image, width: 1300 })
    const [showDialog, setShowDialog] = useState(false)
    const isPopupShown =
      isRestricted && !hideRestrictPopup && showDialog && href

    return (
      <div
        onClick={isRestricted ? () => setShowDialog(true) : undefined}
        data-testid="custom-link"
      >
        <StyledCustomLink
          id={id}
          as="a"
          ref={ref}
          {...(isExternalHref && {
            target: '_blank',
          })}
          rel={rel}
          data-cy={cyTag}
          href={isRestricted && !hideRestrictPopup ? undefined : href}
          $variant={styles?.variant}
          $coverFormat={styles?.cover}
          $borderStyle={buttonBorderStyle}
          $backgroundColor={backgroundColor}
          $textColor={textColor}
          $imageUrl={backgroundImageUrl}
          $hasLeftImage={hasImageBlock}
          $isNested={isNested}
          {...anchorProps}
        >
          {hasImageBlock && (
            <ImageBlock
              image={image}
              alt={label}
              skipErrorHandler={skipImageErrorHandler}
            />
          )}
          <StyledContent
            $variant={styles?.variant}
            $hasLabel={Boolean(label)}
            $isNested={isNested}
            $hasLeftContent={hasImageBlock}
            $hasRightContent={!!priceText || hasChevron}
            style={contentStyles}
          >
            {isNested ? (
              <Typography variant="hint" as="span">
                <StyledSectionTitleForNestedSection>
                  {label}
                </StyledSectionTitleForNestedSection>
              </Typography>
            ) : (
              <StyledSectionTitle>{label}</StyledSectionTitle>
            )}

            {tagline && (
              <TaglineBlock
                text={tagline}
                icon={taglineIcon}
                textColor={textColor}
              />
            )}
          </StyledContent>
          {priceText && (
            <StyledPrice $textColor={textColor}>{priceText}</StyledPrice>
          )}
          {hasChevron && (
            <StyledChevronWrapper
              $textColor={textColor}
              $variant={styles?.variant}
            >
              <Icon.Chevron />
            </StyledChevronWrapper>
          )}
        </StyledCustomLink>

        {isPopupShown && href && (
          <ProceedToLinkPopUp
            proceedUrl={href}
            translations={translations}
            isOpen={true}
            closeDialog={() => setShowDialog(false)}
            onConfirmButtonClick={() => setShowDialog(false)}
            openInNewTab
          />
        )}
      </div>
    )
  }
)
