import styled from 'styled-components'

import { StyledSectionContainer } from '../Section.styles'

export const StyledContainerForSectionContainer = styled(
  StyledSectionContainer
)`
  padding: 16px;
  text-align: center;

  &:hover {
    transform: revert;
  }
`
