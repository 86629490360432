import cloneDeep from 'lodash.clonedeep'

import {
  creatorUrlBuilders,
  fromCommissionAggregateAPI,
  imageUrlBuilder,
} from '@web-apps/utils-shared'
import {
  CreatorPageAffiliateLinksAnalytics,
  CreatorPageAffiliateLinksAnalyticsAPIPayload,
  ImageNamesEnum,
} from '@web-apps/utils-types'

import { getImageByName, LEGACY_AFFILIATE_LINKS } from '../utils'

export const fromCreatorPageAffiliateLinksAnalyticsAPI = (
  response: CreatorPageAffiliateLinksAnalyticsAPIPayload
): CreatorPageAffiliateLinksAnalytics => {
  return {
    results: (response.content || []).map((a) => {
      const earnings = cloneDeep(a.earnings)

      if (!a.affiliate_link) {
        const url = creatorUrlBuilders.creatorUrlBuilder({
          creatorSlug: response._links.parent.name,
        })

        const image = getImageByName(
          response._links['parent/image'],
          ImageNamesEnum.DEFAULT
        )

        return {
          id: LEGACY_AFFILIATE_LINKS,
          description: '',
          originalURL: url,
          affiliateURL: url,
          image,
          earnings,
        }
      }

      const brandImage = getImageByName(
        a.affiliate_link._links['brand/image'],
        ImageNamesEnum.DEFAULT
      )

      const imageUrl = imageUrlBuilder({
        image: a.affiliate_link._links['self/image'] || brandImage,
        width: 256,
        height: 256,
      })

      return {
        id: a.affiliate_link._links.self.id,
        earnings,
        description:
          a.affiliate_link.title || a.affiliate_link._links.brand?.title || '',
        originalURL: a.affiliate_link._links.original?.href || '',
        affiliateURL: a.affiliate_link._links.short?.href || '',
        image:
          imageUrl && (a.affiliate_link._links['self/image'] || brandImage)
            ? {
                href: imageUrl,
                type: (a.affiliate_link._links['self/image'] || brandImage)
                  ?.type,
                name: a.affiliate_link._links['self/image']
                  ? 'product'
                  : 'logo',
              }
            : undefined,
        commission: fromCommissionAggregateAPI(a.affiliate_link.commission),
      }
    }),
    prevAPILink: response._links.prev?.href,
    nextAPILink: response._links.next?.href,
  }
}
