import { imageUrlBuilder } from '@web-apps/utils-shared'
import { CreatorPageType } from '@web-apps/utils-types'

import { getAvatarImage } from '../../utils'

export const computeAvatarUrl = ({
  creatorPage,
}: {
  creatorPage: CreatorPageType
}) =>
  imageUrlBuilder({
    image: getAvatarImage(creatorPage),
    width: 200,
  })
