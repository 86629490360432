import styled, { css } from 'styled-components'

import { Theme } from '@web-apps/ui-shared'

import {
  ButtonBorderStyle,
  ButtonShadowStyle,
  ButtonShapeStyle,
  CustomizationFormProperties,
} from '@web-apps/utils-types'

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 64px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  background-color: ${Theme.Colors.background.base};
  border-radius: ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.BUTTONS_SHAPE]) {
      case ButtonShapeStyle.ROUNDED:
        return '8px'
      case ButtonShapeStyle.PILL:
        return '32px'
      case ButtonShapeStyle.SQUARE:
      default:
        return '0'
    }
  }};
`

export const StyledTikTokContainer = styled.div<{
  $isPreview: boolean
}>`
  position: relative;
  display: flex;
  padding: 24px 0;
  justify-content: center;
  width: 100%;

  box-shadow: ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.BUTTONS_SHADOW]) {
      case ButtonShadowStyle.SOFT:
        return `0px 2px 4px ${
          theme.customization[CustomizationFormProperties.BUTTONS_SHADOW_COLOR]
        }${
          theme.customization[
            CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
          ]
        }`
      case ButtonShadowStyle.HARD:
        return `1px 2px 0px ${
          theme.customization[CustomizationFormProperties.BUTTONS_SHADOW_COLOR]
        }${
          theme.customization[
            CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
          ]
        }`
      default:
        return 'none'
    }
  }};

  background-color: ${Theme.Colors.background.base};

  ${({ theme }) => {
    if (
      theme.customization[CustomizationFormProperties.BUTTONS_BORDER_STYLE] ===
      ButtonBorderStyle.OUTLINE
    ) {
      return css`
        border: 1px solid
          ${theme.customization[
            CustomizationFormProperties.BUTTONS_BORDER_COLOR
          ] || 'rgba(51, 51, 51, 0.8)'};
      `
    }

    return null
  }};
  border-radius: ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.BUTTONS_SHAPE]) {
      case ButtonShapeStyle.ROUNDED:
        return '8px'
      case ButtonShapeStyle.PILL:
        return '32px'
      case ButtonShapeStyle.SQUARE:
      default:
        return '0'
    }
  }};

  & > div {
    width: 438px;
    height: 100%;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`
