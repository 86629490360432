import styled from 'styled-components'

import {
  BREAKPOINTS,
  media,
  Popover,
  ShareLinkPopup,
  Theme,
} from '@web-apps/ui-shared'

import { initialValuesForCustomization } from '../utils'
import {
  BackgroundInfo,
  BackgroundType,
  BackgroundTypeStyle,
  CustomizationFormProperties,
  FontEnum,
} from '@web-apps/utils-types'

const CONTENT_PADDING_IN_PX = 24
const CONTENT_PADDING_WITH_HEADER_BACKGROUND = 152

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: ${Theme.CreatorPage.wrapper.maxWidth}px;
  align-items: center;
  margin: 0 auto;
  padding: 0;

  font-family: ${({ theme }) =>
    theme?.customization?.[CustomizationFormProperties.FONT] || FontEnum.INTER};
  color: ${({ theme }) =>
    theme?.customization?.[CustomizationFormProperties.TEXT] ||
    initialValuesForCustomization[CustomizationFormProperties.TEXT]};
`

export const StyledSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  flex: 1;
`

export const StyledBackground = styled.div<{
  $backgroundInfo: BackgroundInfo
}>`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: ${({ $backgroundInfo }) =>
    $backgroundInfo?.backgroundType === BackgroundType.Solid ||
    $backgroundInfo?.backgroundType === BackgroundType.Header
      ? $backgroundInfo.backgroundColor
      : 'initial'};
  background-image: ${({ $backgroundInfo }) => {
    switch ($backgroundInfo?.backgroundType) {
      case BackgroundType.Gradient:
        return `linear-gradient(
              135deg,
              ${$backgroundInfo.gradientColors.join(',')}
            )`
      // background image is an absolute div in this case
      case BackgroundType.Image:
      case BackgroundType.Solid:
      default:
        return 'unset'
    }
  }};

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${({ $backgroundInfo }) => {
      if ($backgroundInfo?.backgroundType === BackgroundType.Image) {
        switch ($backgroundInfo.backgroundStyle) {
          case BackgroundTypeStyle.FADED_DARK:
            return 'rgba(0,0,0,0.48)'
          case BackgroundTypeStyle.FADED_LIGHT:
            return 'rgba(255,255,255,0.48)'
          case BackgroundTypeStyle.BLURRED_DARK:
            return 'rgba(0,0,0,0.48)'
          case BackgroundTypeStyle.BLURRED_LIGHT:
            return 'rgba(255,255,255,0.48)'
          default:
            return 'unset'
        }
      }
      return 'unset'
    }};
  }
`

export const StyledHeaderImageContainer = styled.div<{
  $backgroundInfo: BackgroundInfo
}>`
  position: absolute;
  width: calc(100% + ${CONTENT_PADDING_IN_PX * 2}px);
  height: 200px;
  margin-top: -${CONTENT_PADDING_WITH_HEADER_BACKGROUND}px;
  z-index: -1;

  ${({ $backgroundInfo }) => {
    if (
      $backgroundInfo.backgroundType === BackgroundType.Header &&
      ($backgroundInfo.backgroundStyle === BackgroundTypeStyle.BLURRED_DARK ||
        $backgroundInfo.backgroundStyle === BackgroundTypeStyle.BLURRED_LIGHT)
    )
      return `
        filter: blur(24px);
      `

    return ''
  }};

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${({ $backgroundInfo }) => {
      if ($backgroundInfo?.backgroundType === BackgroundType.Header) {
        switch ($backgroundInfo.backgroundStyle) {
          case BackgroundTypeStyle.FADED_DARK:
            return 'rgba(0,0,0,0.48)'
          case BackgroundTypeStyle.FADED_LIGHT:
            return 'rgba(255,255,255,0.48)'
          case BackgroundTypeStyle.BLURRED_DARK:
            return 'rgba(0,0,0,0.48)'
          case BackgroundTypeStyle.BLURRED_LIGHT:
            return 'rgba(255,255,255,0.48)'
          default:
            return 'unset'
        }
      }
      return 'unset'
    }};
  }
`

export const StyledFullWrapper = styled.div<{
  $backgroundInfo: BackgroundInfo
  $withAdditionalPadding?: boolean
}>`
  padding: ${CONTENT_PADDING_IN_PX}px;
  ${({ $withAdditionalPadding }) =>
    $withAdditionalPadding ? 'padding-bottom: 96px;' : ''}
  ${({ $backgroundInfo }) =>
    $backgroundInfo?.backgroundType === BackgroundType.Header &&
    `padding-top: ${CONTENT_PADDING_WITH_HEADER_BACKGROUND}px`};

  width: 100%;
  height: 100%;
`

export const StyledSkeletonSectionContainer = styled.div`
  height: 200px;
`

export const StyledShareLinkPopup = styled(ShareLinkPopup)`
  color: initial;
  margin-inline: auto;
  z-index: ${Theme.CreatorPage.footer.zIndex + 1};

  ${media.from(
    BREAKPOINTS.sm,
    `
      top: 128px;
    `
  )}
`

export const StyledHeaderOptionsPopover = styled(Popover)`
  color: initial;
  margin-inline: auto;
  z-index: ${Theme.CreatorPage.footer.zIndex + 1};

  ${media.from(
    BREAKPOINTS.sm,
    `
      top: 128px;
    `
  )}
`

export const StyledHeaderOptionsListItem = styled.a`
  display: block;
  width: 100%;

  padding-top: 16px;
  padding-bottom: 10px;

  /* reset to when using button */
  padding-inline: 0px;
  border: none;
  background: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${Theme.Colors.border.regular};
  }

  &:last-child {
    padding-bottom: ${({ theme }) => `${theme.extraPadding || 10}px`};
  }
`
