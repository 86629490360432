import styled from 'styled-components'
import {
  CustomizationFormProperties,
  HeaderPosition,
  SocialsPlacement,
} from '@web-apps/utils-types'

export const StyledSocialContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  ${({ theme }) => {
    return (
      (theme.customization[CustomizationFormProperties.SOCIALS_PLACEMENT] ===
        SocialsPlacement.BOTTOM ||
        theme.customization[CustomizationFormProperties.HEADER_POSITION] ===
          HeaderPosition.CENTER) &&
      'justify-content: center'
    )
  }};

  path {
    fill: ${({ theme }) =>
      `${theme.customization[CustomizationFormProperties.TEXT]}`};
  }
`
