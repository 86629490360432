import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import cloneDeep from 'lodash.clonedeep'

import {
  api,
  callApiEndpoint,
  encodeUriWhenNotEncoded,
} from '@web-apps/utils-shared'
import {
  ContainerSectionAPIPayloadType,
  ImageFormType,
  ImageNamesEnum,
  ImageResponseType,
  SectionContainerItemFormType,
  SectionDataType,
  SectionType,
  SectionTypeEnum,
  SectionVariantEnum,
} from '@web-apps/utils-types'

export const containerAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    addContainerAtomic: builder.mutation<
      SectionType,
      {
        path: string
        sectionData: SectionDataType
        imageData?: ImageFormType
        redirect?: boolean
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { path, redirect, sectionData, imageData },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const createSectionPayload = cloneDeep(sectionData)

        // removing uploaded images inside items because they will be uploaded independently below
        const items = createSectionPayload.items || []
        items.forEach((i) => delete i.imageData)
        let container
        try {
          container = await callApiEndpoint(
            'addCreatorPageSection',
            _queryApi,
            {
              pageId: '',
              url: path,
              data: createSectionPayload,
              stopInvalidatesTags: true,
            }
          )
        } catch (error) {
          return { error }
        }

        const containerId = container._links.self.id
        const pageId = container._links.parent.id

        // When it has items and need to upload images - one by one
        const itemHasImageToUpload = (item: SectionContainerItemFormType) =>
          item.imageData && !item.imageData.hasImageSource
        const hasImagesToUploadForNestItems =
          sectionData.items && sectionData.items.some(itemHasImageToUpload)
        if (hasImagesToUploadForNestItems) {
          const { error: getSectionError, data: containerSection } =
            await fetchWithBQ({
              path: container._links.self.href,
              method: 'get',
            })

          if (getSectionError) return { error: getSectionError }

          // Uploading images
          const uploadImagePromises: Promise<QueryReturnValue>[] = []
          if (
            (containerSection.items || []).length ===
            (sectionData.items || []).length
          ) {
            const items = sectionData.items || []
            items.forEach((item, i) => {
              const storedSectionItem = (containerSection.items || [])[i]
              const path = storedSectionItem?._links['self/edit-image'].href
              if (path && item.imageData && itemHasImageToUpload(item)) {
                uploadImagePromises.push(
                  callApiEndpoint('setCreatorPageImage', _queryApi, {
                    path,
                    name: ImageNamesEnum.DEFAULT,
                    file: item.imageData.imageFile,
                    stopInvalidatesTags: true,
                  })
                )
              }
            })

            try {
              await Promise.all(uploadImagePromises)
            } catch (error) {
              return { error }
            }
          }

          return { data: container }
        }

        // TODO: END UPLOADING IMAGES FOR ITEMS

        const promises: Promise<QueryReturnValue>[] = []

        // 2. update redirect if needed
        if (redirect) {
          promises.push(
            callApiEndpoint('setPageRedirect', _queryApi, {
              pageId,
              containerId,
            })
          )
        }

        // 3. upload image if needed
        if (
          imageData?.imageFile &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          promises.push(
            callApiEndpoint('setCreatorPageImage', _queryApi, {
              path: `/creator-pages/${pageId}/sections/${containerId}/images`,
              name: ImageNamesEnum.DEFAULT,
              file: imageData.imageFile,
              stopInvalidatesTags: true,
            })
          )
        }

        try {
          await Promise.all(promises)
        } catch (error) {
          return { error }
        }

        // it was well succeeded
        return { data: container }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
    editContainerAtomic: builder.mutation<
      Record<string, never>,
      {
        path: string
        redirect?: 'delete' | 'update'
        sectionData: SectionDataType
        imageData?: ImageFormType
        items?: {
          orderById: string[]
        }
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { path, redirect, sectionData, imageData, items },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        // 1. Edit section
        const container = await callApiEndpoint(
          'editCreatorPageSection',
          _queryApi,
          {
            path,
            data: sectionData,
            stopInvalidatesTags: true,
          }
        )

        // update items order if needed
        if (items?.orderById) {
          const { error: updateItemsOrderError } = await fetchWithBQ({
            path: `${path}/sections/order`,
            method: 'put',
            data: {
              items: items?.orderById,
            },
          })

          if (updateItemsOrderError) return { error: updateItemsOrderError }
        }

        // 2. update redirect if needed
        if (redirect === 'update') {
          await callApiEndpoint('setPageRedirect', _queryApi, {
            pageId: container._links.parent.id,
            sectionId: container._links.self.id,
          })
        }

        // 2. delete redirect if needed
        if (redirect === 'delete') {
          await callApiEndpoint('deletePageRedirect', _queryApi, {
            pageId: container._links.parent.id,
          })
        }

        // 3. Upload image from a device if needed
        if (
          imageData?.imageFile &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          try {
            await callApiEndpoint('setCreatorPageImage', _queryApi, {
              path: container._links['self/edit-image']?.href,
              name: ImageNamesEnum.DEFAULT,
              file: imageData.imageFile,
              stopInvalidatesTags: true,
            })
          } catch (error) {
            return { error }
          }
        }

        // 4. upload image from external source if needed
        if (
          imageData?.hasImageSource &&
          imageData?.href &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          try {
            await callApiEndpoint('setCreatorPageImageFromSource', _queryApi, {
              path: container._links['self/edit-image']?.href,
              name: ImageNamesEnum.DEFAULT,
              source: encodeUriWhenNotEncoded(imageData.href),
              stopInvalidatesTags: true,
            })
          } catch (error) {
            return { error }
          }
        }

        // 5. delete image if needed
        if (sectionData.styles.variant === SectionVariantEnum.PLAIN) {
          const deleteImage = container._links['self/delete-image']?.find(
            (item: ImageResponseType) =>
              item.name === ImageNamesEnum.DEFAULT ||
              item.name === ImageNamesEnum.SECTION
          )

          if (deleteImage) {
            await callApiEndpoint('deleteCreatorPageImage', _queryApi, {
              path: deleteImage.href,
              stopInvalidatesTags: true,
            })
          }
        }

        // it was well succeeded
        return { data: container }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
    getContainerSectionsForAffiliateList: builder.query<
      ContainerSectionAPIPayloadType[],
      { path: string }
    >({
      query: ({ path }) => ({
        path,
        method: 'get',
      }),
      transformResponse: (responseData) => responseData?.content,
    }),

    addSectionToContainerAtomic: builder.mutation<
      SectionType,
      {
        pageId: string
        containerId: string
        sectionData: SectionDataType
        imageData?: ImageFormType
        path: string
      }
    >({
      async queryFn(
        { containerId, sectionData, imageData, pageId, path },
        _queryApi
      ) {
        // The flow with adding an affiliate link is the following: we need to get the list of all the containers
        // and then find the one that we want to add the section to and use the endpoint from the response to add the
        // new product to the container.
        try {
          const containers = await callApiEndpoint(
            'getContainerSectionsForAffiliateList',
            _queryApi,
            {
              path,
              forceRefetch: true,
            }
          )

          const container = containers.find(
            (container: SectionType) => container._links.self.id === containerId
          )

          const section = await callApiEndpoint('addSectionAtomic', _queryApi, {
            sectionData,
            imageData,
            path: container._links['create-in'].href,
          })

          return { data: section }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: ['CreatorDetails', 'CreatorPage'],
    }),
    addContainerWithAffiliateLinkCreation: builder.mutation<
      SectionType,
      {
        path: string
        item: SectionDataType
        affiliateLinkCreationPath: string
        sectionData: SectionDataType
        imageData?: ImageFormType
        redirect?: boolean
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { path, sectionData, item, affiliateLinkCreationPath },
        _queryApi
      ) {
        try {
          const section = cloneDeep(item)
          let pathToCreateContainer = path
          if (!section.link_id) {
            const affiliateLink = await callApiEndpoint(
              'createAffiliateLink',
              _queryApi,
              {
                uri: section.href,
                title: section.label,
                path: affiliateLinkCreationPath,
                stopInvalidatesTags: true,
              }
            )

            section.link_id = affiliateLink.id
            section.type = SectionTypeEnum.AFFILIATE_LINK
            pathToCreateContainer = affiliateLink.api.addToGrid
          }

          const container = await callApiEndpoint(
            'addContainerAtomic',
            _queryApi,
            {
              sectionData: { ...sectionData, items: [section] },
              path: pathToCreateContainer,
            }
          )

          return { data: container }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
  }),
})

export const {
  useAddContainerAtomicMutation,
  useEditContainerAtomicMutation,
  useAddSectionToContainerAtomicMutation,
  useGetContainerSectionsForAffiliateListQuery,
  useAddContainerWithAffiliateLinkCreationMutation,
} = containerAPI
