import {
  ImageNamesEnum,
  ImageResponseType,
  SectionType,
  ImageResponseAPIType,
  ResourceAPIType,
} from '@web-apps/utils-types'
import { getDefaultImageFromAPIResponse } from '@web-apps/utils-shared'

type ImageUtilResultType = ImageResponseType | undefined

export const getImageByName = (
  images: ImageResponseAPIType | undefined,
  name: ImageNamesEnum
): ImageUtilResultType =>
  Array.isArray(images) ? images.find((item) => item.name === name) : undefined

export const getSectionImage = (section: SectionType): ImageUtilResultType => {
  const images = section._links['self/image']

  if (!images) return undefined

  return (
    getDefaultImageFromAPIResponse(images) ||
    getImageByName(images, ImageNamesEnum.SECTION)
  )
}

export const getAvatarImage = (
  creatorPage: ResourceAPIType
): ImageUtilResultType => {
  const images = creatorPage._links['self/image']

  if (!images) return undefined

  return (
    getDefaultImageFromAPIResponse(images) ||
    getImageByName(images, ImageNamesEnum.AVATAR)
  )
}
