import {
  ExternalLinkSection,
  isLeafSection,
  LeafsSectionType,
  ProductRecommendationsSectionType,
} from '@web-apps/utils-types'
import { Box, Grid } from '@web-apps/ui-shared'

import { StyledContainerForSectionContainer } from './ContainerSection.styles'
import { StyledSectionTitle } from '../Section.styles'

type Props = {
  section: ProductRecommendationsSectionType
  renderLeafSection: (
    section: LeafsSectionType,
    options?: {
      isNestedSection: boolean
      containerSection?: ProductRecommendationsSectionType
      isLoading?: boolean
      hideTikTokSections?: boolean
    }
  ) => JSX.Element | null
}

export const ContainerSection = ({ section, renderLeafSection }: Props) => {
  return (
    <StyledContainerForSectionContainer key={section._links.self.id}>
      {section.label && (
        <Box mb={16}>
          <StyledSectionTitle>{section.label}</StyledSectionTitle>
        </Box>
      )}

      {(section.items || []).length > 1 && (
        <Grid gap={16} templateColumns="repeat(2, minmax(45%, 1fr))">
          {section.items.map((s) =>
            isLeafSection(s as ExternalLinkSection)
              ? renderLeafSection(s as LeafsSectionType, {
                  isNestedSection: true,
                  containerSection: section,
                })
              : null
          )}
        </Grid>
      )}

      {(section.items || []).length === 1 &&
      isLeafSection(section.items[0] as ExternalLinkSection)
        ? renderLeafSection(section.items[0] as LeafsSectionType, {
            isNestedSection: true,
            containerSection: section,
          })
        : null}
    </StyledContainerForSectionContainer>
  )
}
