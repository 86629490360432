import { useState } from 'react'
import { Icon } from '@web-apps/ui-shared'
import {
  StyledFooterContainer,
  StyledLink,
  StyledMessageContainer,
} from './Footer.styles'
import { FooterBanner } from './FooterBanner'

type FooterProps = {
  url: string
  title: string
  showBanner: boolean
  showClassic: boolean
  onFooterClick?: () => void
  onBannerClick?: () => void
  onBannerClose?: () => void
}

export const Footer = ({
  url,
  title,
  showBanner,
  showClassic,
  onFooterClick,
  onBannerClick,
  onBannerClose,
}: FooterProps) => {
  const [isActive, setState] = useState(showBanner)

  return (
    <>
      {isActive && (
        <FooterBanner
          title={title}
          url={url}
          onClick={onBannerClick}
          onClose={() => {
            setState(false)
            onBannerClose && onBannerClose()
          }}
        />
      )}
      {showClassic && (
        <StyledFooterContainer $hide={isActive}>
          <StyledLink href={url} onClick={onFooterClick}>
            <StyledMessageContainer>
              <span>{title}</span>
              <Icon.ZezamLogoOld width={66} height={16} />
            </StyledMessageContainer>
          </StyledLink>
        </StyledFooterContainer>
      )}
    </>
  )
}
