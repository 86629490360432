import styled from 'styled-components'
import ReactPlayer from 'react-player'

import {
  ButtonShapeStyle,
  CustomizationFormProperties,
} from '@web-apps/utils-types'

export const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;

  overflow: hidden;
  z-index: 1;
`

export const StyledEmbedVideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
`

export const StyledBorderContainer = styled.div`
  display: flex;
  z-index: 1;

  iframe {
    border: none;

    border-radius: ${({ theme }) => {
      switch (theme.customization[CustomizationFormProperties.BUTTONS_SHAPE]) {
        case ButtonShapeStyle.ROUNDED:
          return '8px'
        case ButtonShapeStyle.PILL:
          return '32px'
        case ButtonShapeStyle.SQUARE:
        default:
          return '0'
      }
    }};
  }
`

export const StyledOverlayLinkWrapper = styled.a`
  position: relative;
`

export const StyledOverlayLinkBlocker = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
`
