import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import {
  api,
  callApiEndpoint,
  encodeUriWhenNotEncoded,
} from '@web-apps/utils-shared'
import {
  ImageFormType,
  ImageNamesEnum,
  ImageResponseType,
  SectionDataType,
  SectionType,
  SectionVariantEnum,
} from '@web-apps/utils-types'

const prepareSectionPayload = (sectionData: SectionDataType) => ({
  ...sectionData,
  href: sectionData.href
    ? encodeUriWhenNotEncoded(sectionData.href)
    : undefined,
  image_source: sectionData.image_source
    ? encodeUriWhenNotEncoded(sectionData.image_source)
    : undefined,
  tagline: sectionData.tagline || undefined,
})

export const sectionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    // TODO: Affiliate links: replace to two parameters in the end: data and path
    addCreatorPageSection: builder.mutation<
      SectionType,
      {
        data: SectionDataType
        url?: string
        pageId: string
        containerId?: string
        stopInvalidatesTags?: boolean
      }
    >({
      query: ({ pageId, containerId, data, url }) => ({
        path:
          url ||
          `/creator-pages/${pageId}/sections${
            containerId ? `/${containerId}/sections` : ''
          }`,
        method: 'post',
        data: prepareSectionPayload(data),
      }),
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
    editCreatorPageSection: builder.mutation<
      SectionType,
      {
        path: string
        data: SectionDataType
        stopInvalidatesTags?: boolean
      }
    >({
      query: ({ path, data }) => ({
        path,
        method: 'put',
        data: prepareSectionPayload(data),
      }),
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
    deleteCreatorPageSection: builder.mutation<
      Record<string, never>,
      { path: string }
    >({
      query: ({ path }) => ({
        path,
        baseUrl: '',
        method: 'delete',
      }),
      transformResponse: () => ({}),
      invalidatesTags: ['CreatorDetails', 'CreatorPage', 'AffiliateLinks'],
    }),
    setPageRedirect: builder.mutation<
      void,
      { path: string; pageId: string; sectionId: string; url?: string }
    >({
      query: ({ url, pageId, sectionId }) => ({
        path: url || `creator-pages/${pageId}/redirect`,
        method: 'put',
        data: {
          section_id: sectionId,
        },
      }),
    }),
    deletePageRedirect: builder.mutation<
      void,
      { path: string; pageId: string; url?: string }
    >({
      query: ({ url, pageId }) => ({
        path: url || `creator-pages/${pageId}/redirect`,
        method: 'delete',
      }),
    }),
    toggleSectionPublishState: builder.mutation<
      null,
      {
        path: string
        data: { published: boolean }
      }
    >({
      query: ({ path, data }) => ({
        path,
        method: 'put',
        data: data,
      }),
      invalidatesTags: ['CreatorDetails', 'CreatorPage'],
    }),
    updateSectionsOrder: builder.mutation<
      Record<string, never>,
      { path: string; sectionsIds: string[]; stopInvalidatesTags?: boolean }
    >({
      query: ({ path, sectionsIds }) => ({
        path,
        method: 'put',
        data: {
          items: sectionsIds,
        },
      }),
      transformResponse: () => ({}),
      invalidatesTags: (result, error, args) =>
        !args.stopInvalidatesTags ? ['CreatorDetails', 'CreatorPage'] : [],
    }),
    setCreatorPageImage: builder.mutation<
      void,
      {
        path: string
        name: ImageNamesEnum
        file: string
        stopInvalidatesTags?: boolean
      }
    >({
      query: ({ path, name, file }) => {
        const data = new FormData()
        data.set('file', file)
        data.set('name', name)

        return { path, method: 'post', data }
      },
      invalidatesTags: (result, error, args) =>
        args.stopInvalidatesTags ? [] : ['CreatorPage', 'CreatorDetails'],
    }),
    setCreatorPageImageFromSource: builder.mutation<
      void,
      {
        path: string
        name: ImageNamesEnum
        source: string
        stopInvalidatesTags?: boolean
      }
    >({
      query: ({ path, name, source }) => ({
        path,
        method: 'post',
        data: { name, source },
      }),
      invalidatesTags: (result, error, args) =>
        args.stopInvalidatesTags ? [] : ['CreatorPage', 'CreatorDetails'],
    }),
    deleteCreatorPageImage: builder.mutation<
      void,
      { path: string; stopInvalidatesTags?: boolean }
    >({
      query: ({ path }) => ({ path, method: 'delete' }),
      invalidatesTags: (result, error, args) =>
        args.stopInvalidatesTags ? [] : ['CreatorPage', 'CreatorDetails'],
    }),
    addSectionAtomic: builder.mutation<
      SectionType,
      {
        path: string
        sectionData: SectionDataType
        imageData?: ImageFormType
        redirect?: boolean
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { redirect, sectionData, imageData, path },
        _queryApi,
        _extraOptions
      ) {
        // 1. Add section
        let section
        try {
          section = await callApiEndpoint('addCreatorPageSection', _queryApi, {
            data: sectionData,
            url: path,
            stopInvalidatesTags: true,
          })
        } catch (error) {
          return { error }
        }

        const promises: Promise<QueryReturnValue>[] = []

        // 2. update redirect if needed
        if (redirect) {
          promises.push(
            callApiEndpoint('setPageRedirect', _queryApi, {
              pageId: section._links.parent.id,
              sectionId: section._links.self.id,
            })
          )
        }

        // 3. upload image if needed
        if (
          imageData?.imageFile &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          promises.push(
            callApiEndpoint('setCreatorPageImage', _queryApi, {
              path: section._links['self/edit-image'].href,
              name: ImageNamesEnum.DEFAULT,
              file: imageData.imageFile,
              stopInvalidatesTags: true,
            })
          )
        }

        try {
          await Promise.all(promises)
        } catch (error) {
          return { error }
        }

        // The section was added successfully
        return { data: section }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
    editSectionAtomic: builder.mutation<
      Record<string, never>,
      {
        path: string
        sectionData: SectionDataType
        imageData?: ImageFormType
        redirect?: 'delete' | 'update'
        stopInvalidatesTags?: boolean
      }
    >({
      async queryFn(
        { path, redirect, sectionData, imageData },
        _queryApi,
        _extraOptions
      ) {
        // 1. Edit section
        const section = await callApiEndpoint(
          'editCreatorPageSection',
          _queryApi,
          {
            path,
            data: sectionData,
            stopInvalidatesTags: true,
          }
        )

        // 2. update redirect if needed
        if (redirect === 'update') {
          await callApiEndpoint('setPageRedirect', _queryApi, {
            pageId: section._links.parent.id,
            sectionId: section._links.self.id,
          })
        }

        // 2. delete redirect if needed
        if (redirect === 'delete') {
          await callApiEndpoint('deletePageRedirect', _queryApi, {
            pageId: section._links.parent.id,
          })
        }

        // 3. Upload image from a device if needed
        if (
          imageData?.imageFile &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          try {
            await callApiEndpoint('setCreatorPageImage', _queryApi, {
              path: section._links['self/edit-image']?.href,
              name: ImageNamesEnum.DEFAULT,
              file: imageData.imageFile,
              stopInvalidatesTags: true,
            })
          } catch (error) {
            return { error }
          }
        }

        // 4. upload image from external source if needed
        if (
          imageData?.hasImageSource &&
          imageData?.href &&
          sectionData.styles.variant !== SectionVariantEnum.PLAIN
        ) {
          try {
            await callApiEndpoint('setCreatorPageImageFromSource', _queryApi, {
              path: section._links['self/edit-image']?.href,
              name: ImageNamesEnum.DEFAULT,
              source: encodeUriWhenNotEncoded(imageData.href),
              stopInvalidatesTags: true,
            })
          } catch (error) {
            return { error }
          }
        }

        // 5. delete image if needed
        if (sectionData.styles.variant === SectionVariantEnum.PLAIN) {
          const deleteImage = section._links['self/delete-image']?.find(
            (item: ImageResponseType) =>
              item.name === ImageNamesEnum.DEFAULT ||
              item.name === ImageNamesEnum.SECTION
          )

          if (deleteImage) {
            await callApiEndpoint('deleteCreatorPageImage', _queryApi, {
              path: deleteImage.href,
              stopInvalidatesTags: true,
            })
          }
        }

        // it was well succeeded
        return { data: {} }
      },
      invalidatesTags: (result, error, args) =>
        result && !args.stopInvalidatesTags
          ? ['CreatorDetails', 'CreatorPage']
          : [],
    }),
  }),
})

export const {
  useDeleteCreatorPageSectionMutation,
  useToggleSectionPublishStateMutation,
  useUpdateSectionsOrderMutation,
  useAddSectionAtomicMutation,
  useEditSectionAtomicMutation,
} = sectionsAPI
