import styled, { keyframes, css } from 'styled-components'

const slideUp = keyframes`
  0% {
    opacity: 1;
    transform: scaleY(1);
    height: auto;
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
    height: 0;
  }
`

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: scaleY(0);
    height: 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    height: auto;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export enum AnimationStateEnum {
  INITIAL = 'initial',
  SHOWN = 'shown',
  HIDDEN = 'hidden',
}

export const StyledContainer = styled.div<{
  $state: AnimationStateEnum
  $isButton?: boolean
}>`
  position: relative;
  transform-origin: top left;

  > div {
    &:hover {
      transform: initial;
    }
  }

  ${({ $state, $isButton }) => {
    if ($state === AnimationStateEnum.SHOWN) {
      return css`
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-name: ${$isButton ? fadeIn : slideDown};
      `
    }

    if ($state === AnimationStateEnum.HIDDEN) {
      return css`
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-name: ${$isButton ? fadeOut : slideUp};
      `
    }

    return null
  }}
`
