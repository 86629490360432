import { IconCatalogPaths, Theme } from '@web-apps/ui-shared'

import { CustomLinkProps } from '../components'
import {
  ImageNamesEnum,
  LinkableSectionType,
  SectionVariantEnum,
} from '@web-apps/utils-types'
import { getSectionImage } from '../utils'
import { SectionLinkAdapterConfigs } from './sectionLink.facade'

export const baseCustomLinkPropsFromSection = (
  section: LinkableSectionType,
  { messages, context }: SectionLinkAdapterConfigs
): Pick<
  CustomLinkProps,
  | 'id'
  | 'label'
  | 'tagline'
  | 'variant'
  | 'image'
  | 'priceText'
  | 'textColor'
  | 'styles'
> => {
  const image = getSectionImage(section)

  return {
    id: section._links.self.id,
    label: section.label,
    tagline: section.tagline,
    styles: section.styles,
    image: image
      ? image
      : context?.containerSection?.styles?.is_product_recommendations
      ? {
          href: IconCatalogPaths.ProductImagePlaceholder,
          name: ImageNamesEnum.DEFAULT,
        }
      : undefined,
    textColor:
      section.styles.variant === SectionVariantEnum.COVER
        ? Theme.CreatorPage.customLink.coverTextColor
        : undefined,
  }
}
