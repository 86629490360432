import React from 'react'
import { useTranslation } from 'next-i18next'
import getConfig from 'next/config'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { themeConfig } from '../../configs/theme.configs'
import { ErrorCodes } from '@web-apps/utils-types'

export const StyledContainer = styled.div`
  max-width: 400px;
  margin: 100px auto;
  text-align: center;

  h1 {
    font-weight: 300;
    margin: 20px 0;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 50px;
    line-height: 24px;
  }

  a {
    color: #000;
    text-decoration: underline;
  }
`

export const Error = ({ error }: { error: ErrorCodes }) => {
  const { t } = useTranslation('common')
  const { publicRuntimeConfig } = getConfig()

  const { icon: LogoIcon, width, height } = themeConfig.logo

  return (
    <StyledContainer>
      <div>
        <a href={publicRuntimeConfig.baseUrls.commercialWebsite}>
          <LogoIcon width={width} height={height} />
        </a>
      </div>
      <h1>{t(`common:${error}_error_title`)}</h1>
      <h2>
        <Trans i18nKey={`common:${error}_error_support_message`}>
          An unexpected error occurred. Please try again. If the error persists,
          please contact us at{' '}
          <a href="mailto:contact@zezam.io">contact@zezam.io</a>.
        </Trans>
      </h2>
      <a href={publicRuntimeConfig.baseUrls.commercialWebsite}>
        {t(`common:${error}_error_back_button_text`)}
      </a>
    </StyledContainer>
  )
}
