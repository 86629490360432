import { Icon, IconAssetsKeys } from '@web-apps/ui-shared'
import { getSocialData, sanitizeSocialSlug } from '@web-apps/utils-shared'

import { CreatorPageType, SocialLinkType } from '@web-apps/utils-types'

import { StyledSocialContainer } from './SocialLinks.styles'

type Props = {
  links: CreatorPageType['social_links']
  socialLinkTracker?: (socialLink: SocialLinkType) => void
}

export const SocialLinks = ({ links, socialLinkTracker }: Props) => {
  if (!links || !links?.length) {
    return null
  }

  return (
    <StyledSocialContainer>
      {links.map((item) => {
        const href = sanitizeSocialSlug(item.type, item.handle)
        const social = getSocialData(item.type)
        if (!href || !social) return null

        const IconComponent = Icon[social.monoIcon as IconAssetsKeys]

        return (
          <a
            key={item.type}
            href={href}
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              if (socialLinkTracker) socialLinkTracker({ ...item, href })
            }}
            onMouseDown={(e) => {
              // track when middle mouse click
              if (e.button === 1 && socialLinkTracker)
                socialLinkTracker({ ...item, href })
            }}
          >
            <IconComponent width={24} height={24} />
          </a>
        )
      })}
    </StyledSocialContainer>
  )
}
