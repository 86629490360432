import styled, { css } from 'styled-components'

import {
  ButtonShapeStyle,
  ButtonThumbnailPositionStyle,
  CustomizationFormProperties,
  ButtonShadowStyle,
} from '@web-apps/utils-types'

const StyleImageBasic = css`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: ${({ theme }) => {
    switch (theme.customization[CustomizationFormProperties.BUTTONS_SHAPE]) {
      case ButtonShapeStyle.ROUNDED:
        return '8px'
      case ButtonShapeStyle.PILL:
        return '80px'
      case ButtonShapeStyle.SQUARE:
      default:
        return '0px'
    }
  }};
  ${({ theme }) =>
    theme.customization[
      CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION
    ] === ButtonThumbnailPositionStyle.LEADING &&
    css`
      position: relative;
      left: -24px;
    `}
  ${({ theme }) =>
    theme.customization[
      CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION
    ] === ButtonThumbnailPositionStyle.STICK_OUT &&
    css`
      position: absolute;
      left: -16px;
      bottom: -8px;
      width: 64px;
      height: 64px;
    `}

  box-shadow: ${({ theme }) => {
    if (
      theme.customization[
        CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION
      ] !== ButtonThumbnailPositionStyle.REGULAR
    ) {
      switch (theme.customization[CustomizationFormProperties.BUTTONS_SHADOW]) {
        case ButtonShadowStyle.SOFT:
          return `0px 2px 4px ${
            theme.customization[
              CustomizationFormProperties.BUTTONS_SHADOW_COLOR
            ]
          }${
            theme.customization[
              CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
            ]
          }`
        case ButtonShadowStyle.HARD:
          return `1px 2px 0px ${
            theme.customization[
              CustomizationFormProperties.BUTTONS_SHADOW_COLOR
            ]
          }${
            theme.customization[
              CustomizationFormProperties.BUTTONS_SHADOW_OPACITY
            ]
          }`
        default:
          return 'none'
      }
    }
    return 'none'
  }};
`

export const StyledImage = styled.img`
  ${StyleImageBasic}
`
