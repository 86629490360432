import { EmbedProvidersEnum, getEmbedProvider } from '@web-apps/utils-shared'
import { IframelyEmbedLink } from './IframelyEmbedLink'
import {
  StyledReactPlayer,
  StyledBorderContainer,
  StyledEmbedVideoContainer,
  StyledOverlayLinkBlocker,
  StyledOverlayLinkWrapper,
} from './ExternalEmbedLink.styles'

type Props = {
  url: string
  openLinkOnClick?: boolean
  hideTikTokSections?: boolean
}

export const ExternalEmbedLink = ({
  url,
  openLinkOnClick = false,
  hideTikTokSections,
}: Props) => {
  const provider = getEmbedProvider(url)

  if (!provider) {
    return null
  }

  const renderEmbed = () => {
    switch (provider.id) {
      case EmbedProvidersEnum.APPLE: {
        url = url.replace(/(music|podcasts).apple.com/, 'embed.$1.apple.com')

        return (
          <StyledBorderContainer>
            <iframe
              title="Apple"
              src={url}
              width="100%"
              height={new URL(url).searchParams.has('i') ? '175px' : '450px'}
              allow="autoplay *; encrypted-media *; fullscreen *"
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
            />
          </StyledBorderContainer>
        )
      }
      case EmbedProvidersEnum.DEEZER: {
        const pathNameParts = new URL(url).pathname.split('/').filter(Boolean)
        const resource = pathNameParts[pathNameParts.length - 2]
        const resourceId = pathNameParts[pathNameParts.length - 1]

        url = `https://widget.deezer.com/widget/auto/${resource}/${resourceId}`

        if (resource === 'artist') {
          url = `${url}/top_tracks`
        }

        return (
          <StyledBorderContainer>
            <iframe
              title="Deezer"
              src={url}
              width="100%"
              height="300px"
              allow="encrypted-media; clipboard-write"
            />
          </StyledBorderContainer>
        )
      }
      case EmbedProvidersEnum.SPOTIFY: {
        const pathNameParts = new URL(url).pathname.split('/').filter(Boolean)
        const resource = pathNameParts[pathNameParts.length - 2]
        const resourceId = pathNameParts[pathNameParts.length - 1]
        let height = '100%'

        switch (resource) {
          case 'track':
            height = '80px'
            break
          case 'show':
          case 'episode':
          case 'playlist':
          case 'album':
            height = '152px'
            break
          case 'artist':
            height = '152px'
            break
          default:
            height = '300px'
        }

        return (
          <StyledBorderContainer>
            <iframe
              title="Spotify"
              src={`https://embed.spotify.com/?uri=spotify:${resource}:${resourceId}`}
              width="100%"
              height={height}
              allow="encrypted-media"
            />
          </StyledBorderContainer>
        )
      }
      case EmbedProvidersEnum.YOUTUBE:
        return (
          <StyledBorderContainer>
            <StyledEmbedVideoContainer>
              <StyledReactPlayer
                url={url}
                width="100%"
                height="100%"
                controls
              />
            </StyledEmbedVideoContainer>
          </StyledBorderContainer>
        )
      case EmbedProvidersEnum.SOUND_CLOUD:
      case EmbedProvidersEnum.VIMEO:
      case EmbedProvidersEnum.TWITCH:
      case EmbedProvidersEnum.AMAZON:
      case EmbedProvidersEnum.BANDCAMP:
        return (
          <StyledBorderContainer>
            <IframelyEmbedLink url={url} />
          </StyledBorderContainer>
        )
      case EmbedProvidersEnum.TIKTOK:
        if (hideTikTokSections) {
          return null
        }

        return (
          <StyledBorderContainer style={{ justifyContent: 'center' }}>
            <IframelyEmbedLink url={url} isTikTok />
          </StyledBorderContainer>
        )
      default:
        return null
    }
  }

  return openLinkOnClick ? (
    <StyledOverlayLinkWrapper
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledOverlayLinkBlocker />
      {renderEmbed()}
    </StyledOverlayLinkWrapper>
  ) : (
    renderEmbed()
  )
}
